<app-header ></app-header>
<div class="cont">
<div>
    <h1>Estetista a Trieste</h1>
    <h2>Lista completa di tutti i centri estetici ed estetiste nel comune di Trieste<br><br></h2>
    <div class="no_mob">
    <h3>Centro estetico</h3>
    <h3>Centro benessere</h3>
    <h3>Estetista</h3>
    <h3>Epilazione</h3>
    <h3>Massaggio</h3>
    <h3>Trattamenot cellulite</h3>
    <h3>Manicure</h3>
    <h3>Pedicure</h3>
    <h3>Unghie artificali</h3>
    <h3>Solarium</h3>
    <h3>Spa</h3>
    <h3>Smalto permanente</h3>
    <h3>Depilazione intima</h3>
    <h3>Cura cutanea</h3>
    <h3>Estetica integrata</h3>
</div>
</div>
<div class="img-cont col-sm-12">
    <img src='../assets/4180425.svg' title="Estetista a Trieste" alt='Estetista a Trieste'>
</div>
</div>
<div class="list_activities">
    <div class="activity col-md-5 offset-md-1 col-sm-12" *ngFor='let doc of eye_doctors; let ind = index'>
        <div class="img_cont">
            <img *ngIf='doc.img' src='{{doc.img}}' title="{{doc.name}}" alt='{{doc.name}}'>
            <img *ngIf='!doc.img' src='../assets/makeup.svg' title="{{doc.name}}" alt='{{doc.name}}'>
        </div>
        <div class="text_activity" >
            <h2>{{doc.name}}</h2> <img class="img_verified" *ngIf='doc.verified' src='../assets/icons/check.svg'>
            <h4>Estetista - {{doc.address}}</h4>
            <a [routerLink]="doc.url"><button class="book" *ngIf='doc.verified'>Vedi disponibilità</button ></a><a [routerLink]="doc.url"><button class="info">Magg. informazioni</button></a>
        </div>
    </div>

</div>
<app-footer></app-footer>
