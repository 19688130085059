<div class="background_payment" >
    <div class="background_payment1" id='contract'>
        <div class="close-catalog" (click)='close()'>  <img src="../assets/icons/closewhite.svg"/></div>
      <div class="document">
        <h1>prenota</h1>
        <h5>Per i primi 30 giorni è gratis.</h5>
        <div class="confirm-pay1"><button >Iscriviti ora e ricevi un mese gratis</button></div>
   
     
      <div style="text-align: left; width: 100%; padding: 40px">
       <h5>Ricevarai:</h5><br>
        <h4><b>Un'agenda personalizzata online.</b></h4> 
         <p> Accessibile da <b>pc</b> e <b>cellulare</b>, da te e da tutti i tuoi dipendenti.</p>
         <br>
         <!-- <h4><b>Ricevi una notifica quando ricevi un appuntamento </b></h4>  
         <p>A qualsiasi ora del giorno, senza dover rivecere chiamate e venir disturbato.</p> -->
         <h4 ><b>I tuoi clienti potranno prenotarsi l'appuntamento da soli.</b></h4> 
         <p>Attraverso l'app o il sito, avranno la possibilità di vedere le tue disponibilità scegliendo la data che più li soddisfa.</p> 
         <br>
         <h4><b>Ricevi una notifica quando ricevi un appuntamento </b></h4>  
         <p>A qualsiasi ora del giorno, senza dover ricevere chiamate ed  essere disturbato.</p>
         <br>
         <h4 ><b>Riceverai una pagina business personalizzata sul nostro sito.</b></h4> 
         <p>In questa pagina saranno visibili i dati più rilevanti del tuo business. Ciò ti aiuterà a dare informazioni sulla tua attività a chi ancora non ti conosce.</p>
      </div>
 
       
<div class="pay-cont">
    <h1>Seleziona un metodo di pagamento</h1>
  <div class="payment-selection"> 
    <div [class.active]='cardvisibility=="block"' (click)='stripeOutbound()'><img src='../assets/icons/payment-card.svg'/><p>Carta di credito</p></div>
    <!-- <div [class.active]='paypalvisibility=="block"' (click)='swichView(2)'><img src='../assets/icons/paypal.svg'/><p>Paypal</p></div> -->
    <div [class.active]='ibanvisibility=="block"' (click)='swichView(3)'><img src='../assets/icons/buildings.svg'/><p>IBAN</p></div>
  </div>
  
  <div><p style="font-weight: 200; padding: 20px 50px;">Tutti i metodi di pagamento su <span style="font-family: 'Comfortaa' !important;
    color: #0061d5;">prenota</span> sono powered by <a href="https://stripe.com/it" target="_blank"><svg class="InlineSVG Icon Footer-PoweredBy-Icon Icon--md" focusable="false" width="33" height="15"><g fill-rule="evenodd"><path d="M32.956 7.925c0-2.313-1.12-4.138-3.261-4.138-2.15 0-3.451 1.825-3.451 4.12 0 2.719 1.535 4.092 3.74 4.092 1.075 0 1.888-.244 2.502-.587V9.605c-.614.307-1.319.497-2.213.497-.876 0-1.653-.307-1.753-1.373h4.418c0-.118.018-.588.018-.804zm-4.463-.859c0-1.02.624-1.445 1.193-1.445.55 0 1.138.424 1.138 1.445h-2.33zM22.756 3.787c-.885 0-1.454.415-1.77.704l-.118-.56H18.88v10.535l2.259-.48.009-2.556c.325.235.804.57 1.6.57 1.616 0 3.089-1.302 3.089-4.166-.01-2.62-1.5-4.047-3.08-4.047zm-.542 6.225c-.533 0-.85-.19-1.066-.425l-.009-3.352c.235-.262.56-.443 1.075-.443.822 0 1.391.922 1.391 2.105 0 1.211-.56 2.115-1.39 2.115zM18.04 2.766V.932l-2.268.479v1.843zM15.772 3.94h2.268v7.905h-2.268zM13.342 4.609l-.144-.669h-1.952v7.906h2.259V6.488c.533-.696 1.436-.57 1.716-.47V3.94c-.289-.108-1.346-.307-1.879.669zM8.825 1.98l-2.205.47-.009 7.236c0 1.337 1.003 2.322 2.34 2.322.741 0 1.283-.135 1.581-.298V9.876c-.289.117-1.716.533-1.716-.804V5.865h1.716V3.94H8.816l.009-1.96zM2.718 6.235c0-.352.289-.488.767-.488.687 0 1.554.208 2.241.578V4.202a5.958 5.958 0 0 0-2.24-.415c-1.835 0-3.054.957-3.054 2.557 0 2.493 3.433 2.096 3.433 3.17 0 .416-.361.552-.867.552-.75 0-1.708-.307-2.467-.723v2.15c.84.362 1.69.515 2.467.515 1.879 0 3.17-.93 3.17-2.548-.008-2.692-3.45-2.213-3.45-3.225z"></path></g></svg></a>, <br>il metodo di pagamento online più sicuro al mondo</p></div>
        <!-- <form (submit)="handleForm($event)" [style.display]='cardvisibility'  id="payment-form-card" >
            <div class="row inline">
                <div class="col-md-6" style="padding: 5px 20px; text-align: left;">
                  <label >
                   Nome e Cognome
                  </label>
                  <input id="name" name="name" placeholder="Mario Rossi" required>
                </div>
                <div class="col-md-6" style="padding: 5px 20px; text-align: left;">
                  <label >
                   Email
                  </label>
                  <input id="email" name="email" type="email" placeholder="mario.rossi@email.com" required>
                </div>
              </div>
          <div class="row inline">
              <div class="col-md-6" style="padding: 15px 20px 5px 20px; text-align: left;">
                <label>
                 Carta di credito
                </label>
            </div>
            <div  class="col-md-12" style="padding: 5px 20px; text-align: left;">
            <div id="card-element">
              
            </div>
        </div>
        </div>
        
          
          <p>{{ cardErrors }}</p>
        
        <div class="confirm-pay"> <button  > Iscriviti {{displ_ammount}}€</button></div>
        <div id="mandate-acceptance">
            By providing your card details and confirming this payment, you are
            authorizing Prenota.srl  and Stripe, our payment service
            provider, to send instructions to your bank to debit your account and
            your bank to debit your account in accordance with those instructions.
            You are entitled to a refund from your bank under the terms and
            conditions of your agreement with your bank. A refund must be claimed
            within 8 weeks starting from the date on which your account was debited.
          </div>
        </form> -->
      

        <form action="/charge" method="post" id="payment-form" [style.display]='ibanvisibility'>
          <div class="row inline">
              <div class="col-md-6" style="padding: 5px 20px; text-align: left;">
                <label for="accountholder-name">
                 Nome e Cognome
                </label>
                <input id="accountholder-name"
                name="accountholder-name"
                placeholder="Mario Rossi"
                required
                [(ngModel)]="accountholder_name">
              </div>
              <div class="col-md-6" style="padding: 5px 20px; text-align: left;">
                <label for="email">
                 Email
                </label>
                <input  id="email"
                name="email"
                type="email"
                placeholder="mario.rossi@email.com" 
                required 
                [(ngModel)]="email">
              </div>
            </div>
          {{sepaErr}}
          <div class="row inline">
              <div class="col-md-6" style="padding: 15px 20px 5px 20px; text-align: left;">
            <label for="iban-element">
              IBAN
            </label>
          </div>

          
          <div  class="col-md-12" style="padding: 5px 20px; text-align: left;">
          <div id="iban-element"></div>
        </div>
      </div>
          <!-- <div class="confirm-pay">  -->
              <div class="confirm-pay">   <button id="submit-button" data-secret="pi_1HRJOYBgAcDl7NCbkEdWuGTs_secret_Wa1oNUfuBUPtKIy3hUTAjMoSA">Provaci gratis</button></div>
          <!-- </div> -->
        
         
          <!-- Display mandate acceptance text. -->
          <div class="mandate-ita">
            Fornendo il tuo IBAN e confermando questo pagamento, autorizzi Prenota e Stripe, il nostro fornitore di servizi di pagamento, a inviare istruzioni alla tua banca per addebitare il tuo conto in conformità a tali istruzioni. Hai diritto ad un rimborso dalla tua banca secondo i termini e le condizioni del tuo contratto con la tua banca. Un rimborso deve essere richiesto entro 8 settimane a partire dalla data in cui è stato addebitato il tuo conto.
          </div>
          <div id="mandate-acceptance">
            By providing your IBAN and confirming this payment, you are
            authorizing Prenota  and Stripe, our payment service
            provider, to send instructions to your bank to debit your account and
            your bank to debit your account in accordance with those instructions.
            You are entitled to a refund from your bank under the terms and
            conditions of your agreement with your bank. A refund must be claimed
            within 8 weeks starting from the date on which your account was debited.
          </div>
           <!-- Used to display form errors. -->
           <div id="error-message" role="alert"></div>
        
        </form>
      <!-- <form action="/form" method="post" id="setup-form" [style.display]='ibanvisibility'>
          <div class="form-row inline">
            <div class="col">
              <label for="accountholder-name">
              Nome completo
              </label>
              <input
                id="accountholder-name"
                name="accountholder-name"
                placeholder="Mario Rossi"
                required
              />
            </div>
        
            <div class="col">
              <label for="email">
                Email
              </label>
              <input
                id="email"
                name="email"
                type="email"
                placeholder="mario.rossi1964@prenota.it"
                required
              />
            </div>
          </div>
      
          <div class="form-row">
            
            <label for="ibanElement">
              IBAN
            </label>
            <div #ibanElement>

            </div>
          </div>
        
       
          <div  class='confirm-pay'>
              <button id="submit-button"  data-secret="{CLIENT_SECRET}">
                  Bonifico SEPA 
                  </button>
          </div>
      
        
       
        <div id="error-message" role="alert"></div>
      
       
        <div id="mandate-acceptance">
          By providing your IBAN, you are authorizing Prenota srl and Stripe,
          our payment service provider, to send instructions to your bank to debit
          your account in accordance with those instructions. Subsequent payments are
          entitled to a refund from your bank under the terms and conditions of your
          agreement with your bank. A refund must be claimed within eight weeks
          starting from the date on which your account was debited.
        </div>
      </form>
     -->
</div>
</div>
</div>
</div>
<!-- <path d="M54.783 50H3.217A3.217 3.217 0 010 46.783V11.217A3.217 3.217 0 013.217 8h51.566A3.217 3.217 0 0158 11.217v35.566A3.217 3.217 0 0154.783 50" fill="#8798cc"></path>
<path fill="#556180" d="M0 13h58v8H0z"></path>
<path d="M15 26H6a1 1 0 100 2h9a1 1 0 100-2M29 27a1 1 0 00-1-1h-9a1 1 0 100 2h9a1 1 0 001-1M7 31H6a1 1 0 100 2h1a1 1 0 100-2M13 31h-2a1 1 0 100 2h2a1 1 0 100-2M18 31h-1a1 1 0 100 2h1a1 1 0 100-2M24 31h-2a1 1 0 100 2h2a1 1 0 100-2M27.29 31.29c-.181.19-.29.45-.29.71 0 .26.109.52.29.71.188.18.438.29.71.29.26 0 .519-.11.71-.29.18-.19.29-.45.29-.71 0-.26-.11-.52-.29-.71-.37-.37-1.05-.37-1.42 0" fill="#b0d3f0"></path>
<path fill="#91bae1" d="M36 34h16v10H36z"></path>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 58" class="dq9KjIYjYi5cLAX4PQJAL"><path d="M54.783 50H3.217A3.217 3.217 0 010 46.783V11.217A3.217 3.217 0 013.217 8h51.566A3.217 3.217 0 0158 11.217v35.566A3.217 3.217 0 0154.783 50" fill="#8798cc"><path d="M54.783 50H3.217A3.217 3.217 0 010 46.783V11.217A3.217 3.217 0 013.217 8h51.566A3.217 3.217 0 0158 11.217v35.566A3.217 3.217 0 0154.783 50" fill="#8798cc"></path></path><path fill="#556180" d="M0 13h58v8H0z"></path><path d="M15 26H6a1 1 0 100 2h9a1 1 0 100-2M29 27a1 1 0 00-1-1h-9a1 1 0 100 2h9a1 1 0 001-1M7 31H6a1 1 0 100 2h1a1 1 0 100-2M13 31h-2a1 1 0 100 2h2a1 1 0 100-2M18 31h-1a1 1 0 100 2h1a1 1 0 100-2M24 31h-2a1 1 0 100 2h2a1 1 0 100-2M27.29 31.29c-.181.19-.29.45-.29.71 0 .26.109.52.29.71.188.18.438.29.71.29.26 0 .519-.11.71-.29.18-.19.29-.45.29-.71 0-.26-.11-.52-.29-.71-.37-.37-1.05-.37-1.42 0" fill="#b0d3f0"></path><path fill="#91bae1" d="M36 34h16v10H36z"></path></svg> -->