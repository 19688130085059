<div id='prof'class='proof'>
    <p style="color: #666;
    font-size: 11px;
    z-index: 100;
    position: absolute;
    top: 10px;
    right: 20px;
    cursor: pointer;
    margin: 0px;" (click)='close()'>Chiudi</p>
    <div class='img_top'>
        <img src='../assets/tk_sponsore.png'>
    </div>
<div  class='proof_txt'>

<!-- <div class="img-cont"><img src='../assets/tk_business.jpeg'></div> -->
<div style="padding: 10px;"><p class="title"><b>{{number}} persone a Trieste</b> hanno usato <b>Prenota</b> per prendere appuntamento nelle ultime <b>24h</b></p>
    <button (click)='goSponsored()'>Inizia a riceve prenotazioni online</button>

</div>

</div>
</div>
<div style='background: #00479d ' >
<div class="productivity_area" >
<div style="position: relative;" >
    <div class="container">
        <div class="row align-items-center">
            <div class="col-xl-7 col-md-12 col-sm-12 col-lg-6" >
                <h3 class="wow fadeInDown" >Scarica l'app ora!<br>
                  </h3>
            </div>
            <div class="col-xl-5 col-md-12 col-sm-12 col-lg-6">
                <div class="app_download wow fadeInDown"  style="text-align: center !important;">
                    <a href="https://apps.apple.com/app/id1523525291" target="_blank">
                            <img src="../assets/applab/img/ilstrator/app.svg" alt="">
                    </a>
                    <a href="http://play.google.com/store/apps/details?id=io.prenota.client" target="_blank">
                            <img src="../assets/applab/img/ilstrator/play.svg" alt="">
                    </a>

                </div>
            </div>
        </div>

    </div>
    <!-- <div class="made-italy">
        Made in Italy <img src='https://upload.wikimedia.org/wikipedia/en/0/03/Flag_of_Italy.svg'>
    </div> -->
</div>

<!--/ productivity_area  -->

<!-- footer start -->
<footer class="footer">
    <div class="contacts">
        <a href="mailto:business@prenota.cc" target="_blank"><div class="contact"><img src='../assets/icons/email.svg'>business@prenota.cc</div></a>
       <!-- <a href="tel:+393312233645" target="_blank"><div class="contact"><img src='../assets/icons/phone.svg'>+39 331 2233645</div></a> -->
    </div>
    <div class="copyrights no_mob"><a [routerLink]="['/estetista/trieste']">Estetista a Trieste </a> - <a [routerLink]="['/unghie/trieste']"> Unghie a Trieste </a>  - <a [routerLink]="['/campi_da_calcetto/trieste']"> Campi da calcio a Trieste </a>  -  <a [routerLink]="['/campi_da_tennis/trieste']"> Campi da tennis a Trieste </a>  -  <a [routerLink]="['/oculista/trieste']">Oculista Trieste</a> -  <a [routerLink]="['/fisioterapista/trieste']">Fisioterapista Trieste</a>  </div>
    <div class="copyrights">Copyright © 2021 Prenota -  All rights reserved.</div>
    <a class="copyrights" href="https://www.letterfy.co/">Best Cover letters generated by AI</a>
    <a class="copyrights" href="https://www.simpleworkapps.com/slack-coffee-chat-app/" >Best Coffee Chat App for Slack</a>

    </footer>
</div>
</div>
