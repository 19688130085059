<div class="cont">

<div class="payment_cont">
    <div class="info"><img src='../assets/icons/info.svg'></div>
   
        <h3>Importo dovuto:</h3>
            <h2>€26.00</h2>
        <div class="center_v">
            <div id="payment-request-button">
                <!-- A Stripe Element will be inserted here. -->
              </div>
              <!-- <button class="apple_pay"> <img src='../assets/icons/logo-apple.svg'> Pay</button> -->
           <p *ngIf='fast_pay_method'>oppure</p>
           <!-- <p >oppure</p> -->
          
            <button *ngFor='let el of paymentmethods' class="existing_card" (click)='showNewCard()'><div><span style="text-align: left;"> <img src='../assets/icons/{{el.card.brand}}.svg'>  **** **** **** {{el.card.last4}} </span><span style="text-align: right;">{{el.card.exp_month}}/{{el.card.exp_year - 2000}}</span></div></button>
            <p [style.display]='new_card_display' style="margin:10px auto 30px">Aggiungi una carta</p>
            <div id="card-element"  [style.display]='new_card_display'>
                
            </div>
            <button class="credit_card" (click)='showNewCard()'>{{card_btn_txt}}</button>
        </div>
    <div class="pay_on_spot">
        <p>Paga sul posto</p>
    </div>
</div>
</div>