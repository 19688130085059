<app-header></app-header>


    <div class="home">
        <div class="appo_list">
            <h1 *ngIf="logged" style="text-align: center; padding: 10px;">I tuoi appuntamenti</h1>
            <div *ngIf="logged" class="appo-1" (click)="navListing()">
                    <h1>Prenota un nuovo appuntamento</h1>
                    <img src="../../assets/icons/more_white.svg" >
                </div>
            <div *ngIf="logged" class="appos" >
                <div class="appo" *ngFor='let appointment of appointments_list'>
                    <h1>{{appointment.store_name}}</h1>
                    <h3>{{appointment.details}}</h3>
                    <div class="appo_date">
                        <h4>{{appointment.day}} {{months_names[appointment.month]}} {{appointment.year}} ore {{times[appointment.start_t]}} </h4>
                    </div>
                    <div class="note_btn" (click)='AddNote(appointment)'> <span *ngIf="appointment.note=='' || appointment.note==undefined ">Aggiungi nota</span> <span *ngIf='appointment.note!="" && appointment.note!=undefined'>Modifica nota</span><img src='../../assets/icons/notes.svg'   alt="Aggiungi una nota" title="Aggiungi una nota"></div>
                    <div class="delete_btn" (click)='deletestorage(appointment)'>Cancella appuntamento  <img src='../../assets/icons/delete_white.svg'> </div>

                </div>
            <div class="log_out" (click)='logout()'><img src="../assets/icons/logout.svg"/> Esci</div>
            <div class="log_out1" (click)='logout()'><img src="../assets/icons/logout_blue.svg"/> Esci</div>
            </div>
            <div *ngIf="!logged" class="appos-1" >
                    <!-- <div class="page-wrapper bg-gra-02 p-t-70 p-b-30 font-poppins"> -->
                        <div class="wrapper wrapper--w680 top_log" >
                            <div class="card card-4">
                                <div class="card-body">
                                    <h2 class="title">Accedi per vedere i tuoi appuntamenti</h2>
                                    <form >
                                        <div class="row row-space">
                                            <p *ngIf='error!=""'>{{error}}</p>
                                                <div class="col-2-r col-sm-8 col-md-6">
                                                        <div class="input-group">
                                                            <label class="label">Email</label>
                                                            <input class="input--style-4" [(ngModel)]="email" type="email" name="email" placeholder="mario.rossi@email.com">
                                                        </div>
                                                    </div>
                                                    <div class="col-2-r col-sm-8 col-md-6">
                                                        <div class="input-group">
                                                            <label class="label">Password</label>
                                                            <input class="input--style-4" [(ngModel)]="password" type="password" name="password"  placeholder="********">
                                                        </div>
                                                    </div>
                                                </div>
                                        <div class="p-t-15" style="text-align: center;">
                                            <button class="btn btn--radius-2 btn--blue col-sm-6 col-md-4"  (click)='login()' >Login</button>
                                        </div>
                                    </form>
                                </div>
                                <div class="bottom-login">Hai dimenticato la password? <a (click)='goResetPassword()'>Cambiala</a></div>
                                <br><br>
                                <div class="bottom-login">Non hai ancora un account? <a (click)='navListing()'> Prenota un appuntamento</a></div>
                            </div>
                        </div>
                    </div>
                </div>
        <!-- </div> -->
    </div>


<div class="cont-reg" [style.display]="register_form">
      
    <div class="login-form" >
            <div class="close-note" (click)='register_form="none"'>  <img src="../assets/icons/close.svg"/></div>
            <h1>prenota</h1>
            <div class='inputs'>
            <p *ngIf='error!=""'></p>
            <label class="label">Nota</label>
            <input class="input--style-4"  type="text"  name="note" placeholder="La prenotazione è a nome di mio figlio Luca" [(ngModel)]='note'>
                    <div class="button-reg">  <button (click)='updateAppo()'>{{button_text}}</button></div>
            </div> 
        </div>
</div>
<app-footer></app-footer>