

        <app-header></app-header>
        <div class="banner">
          <div class="container">
            <h1 class="font-weight-semibold"><br>Prenotazioni online a Trieste</h1>
            <h6 *ngIf='show_sublinks' class="font-weight-normal text-muted pb-3" style="padding:30px auto !important;" >
                <p> - Più del 95% dei clienti consigliano Prenota a nuove attività - </p>
              <a  [routerLink]="['/appuntamento/Wellness_Clinic']">Medico Sportivo</a> &nbsp;- &nbsp;<a [routerLink]="['/home/ricerca/parrucchieri']">Parrucchiere</a> &nbsp; - &nbsp;  <a  [routerLink]="['/home/ricerca/campi']">Campo da calcetto</a> &nbsp; - &nbsp;<a  [routerLink]="['/appuntamento/Cmassaggi']">Massaggio rigenerante</a>
            </h6>
            <div>
              <a [routerLink]="['/business']"><button class="btn btn-opacity-light mr-1"> Gestisco un'<br>attività</button></a>
              <a [routerLink]="['home/ricerca']"*ngIf='show_sublinks'><button class="btn btn-opacity-success ml-1" >Prenota ora</button></a>
              <a href='https://mobile.prenota.cc' *ngIf='!show_sublinks'><button class="btn btn-opacity-success ml-1" >Prenota ora</button></a>

            </div>
            <img src="../assets/Calendar0000.png" alt="Prenota Trieste Online"  />
          </div>
        </div>


            <app-footer></app-footer>

