<app-header ></app-header>
<div class="cont">
<div>
    <h1>Campo da calcio a Trieste</h1>
    <h2>Lista completa di tutti i campi da calcetto a cinque (5) <br>e campi da calcetto a sette (7)  nel comune di Trieste<br><br></h2>
</div>
<div class="img-cont col-sm-12">
    <img src='../assets/soccer_field.svg' title="Campi da calcetto a Trieste" alt='Campi da calcetto Trieste'>
</div>
</div>
<div class="list_activities">
    <div class="activity col-md-5 offset-md-1 col-sm-12 " *ngFor='let doc of eye_doctors; let ind = index'>
        <div class="img_cont">
            <img *ngIf='doc.img' src='{{doc.img}}' title="{{doc.name}}" alt='{{doc.name}}'>
            <img *ngIf='!doc.img' src='../assets/soccer-ball.svg' title="{{doc.name}}" alt='{{doc.name}}'>
        </div>
        <div class="text_activity" >
            <h2>{{doc.name}}</h2> <img class="img_verified" *ngIf='doc.verified' src='../assets/icons/check.svg'>
            <h4>Campo da calcio  - {{doc.address}}</h4>
            <a [routerLink]="doc.url"><button class="book" *ngIf='doc.verified'>Vedi disponibilità</button ></a><a [routerLink]="doc.url"><button class="info">Magg. informazioni</button></a>
        </div>
    </div>

</div>
<app-footer></app-footer>
