
<div class="top-bar">
        <!-- <div class="toast-s" [style.display]='toastx'>{{toast_text}}</div> -->
        <app-header></app-header>
        <div class="service" >
            <!-- <h2>Prenota ora</h2> -->
            <!--   (mouseout)="ser='none'" -->
            <div (click)="showServices()">
                <span class="services-cont"[style.display]='serv_displ'>
                    <span class="services"  >
                            <!-- (mouseover)="ser='block'" -->
                            <h2 style="text-align: center; padding: 30px; font-size: 24px;">Seleziona uno o più servizi</h2>
                            <span class="time-available" *ngFor='let service of services, let i = index ' [style.background]='active_services[i][0]' [style.color]='active_services[i][1]'(click)="selectService(service,i)">{{service.name}}<span [style.color]='active_services[i][3]'>{{time_duration[service.duration-1]}}</span><!--<span class="lefty" [style.color]='active_services[i][3]'>da 50€</span>--></span>
                            <!-- <span  (click)='closeBooking()' class="time-available" style="color: #263b56">Chiudi</span> -->
                            <span  *ngIf='service.length>0' (click)='showSpots()' class="time-available" style="color: #fff; background: #00479d;">Prosegui</span>
                    </span>
                    <div class="close" (click)='closeBooking()'>Annulla</div>
                </span>
                <p style="overflow:hidden; text-overflow:ellipsis; height: 60px; font-size: 18px;"> {{selected_service}}</p>
            </div>
            <div  (click)="showCalendar()">
                    <span class="services-cont services-cont2 "[style.display]='cal'>
                        <span class="white_ss">
                            <span class="time-available1" *ngFor='let date_avi of uniques, let ind = index' (click)="DatePicker(date_avi, ind, true)" [class.active]='active_date[ind]'>{{week_name[date_avi.week_day]}} {{date_avi.number}} {{months_short[date_avi.month]}}</span>
                            <div class="close" (click)='closeBooking()'>Annulla</div>
                        </span>
                    </span>
                    <p style="font-size: 18px;">{{selected_date}}</p>
            </div>
            <div  (click)="showSpots()">
                 
                    <p style="font-size: 18px;"> {{displ_hour}}</p>
                    <!--  -->
                    <span class="services-cont services-cont3"[style.display]='spots'>
                          
                    <span class="bookings-time" >
                            <span class="time-available" *ngFor='let serv of service, let i = index '  >{{serv.name}} 
                                <select class='select_no_margin_left' [(ngModel)]="filtr[i]"  (change)='filter_serv()'   >
                                    <option *ngFor='let empl of empl_for_service[i]'  value={{empl.id}} >{{empl.name}}</option>
                            </select></span>
                            <!-- <h2 style="text-align: center; padding: 30px; font-size: 21px;">{{total_service.name}} in data {{selected_date}}</h2> -->
                        <!-- <span *ngFor="let employee of employees_list, let ind =index "> -->
                            <span class="employee-spot"  >
                                <!-- *ngIf='employee.employee==2' -->
                            <!-- <h3>{{employee.name}}</h3> -->
                            <!-- <p class='nada' *ngIf='selected_date!="Seleziona data"  && service!=undefined && availableSpots.length!=0'> Scorri verso il basso per verdere altri orari</p> -->
                            <p class='nada' style="padding: 10px 0px; font-size: 16px;" *ngIf='selected_date=="Seleziona data" || selected_service=="Seleziona servizio"'>Seleziona prima una data e un servizio</p>
                            <span class="cont-timeslots">

                                    <!-- <p class='nada' *ngIf='results_empl_serv[ind][0].length!=0 && results_empl_serv[ind].length==service.length'> Scorri verso il basso per verdere altri orari</p> -->
                                <span *ngFor='let spot of final_spots_displ'>
                                    <span class="time-available2"  (click)="selectTime(spot)"> {{times[spot[0].start]}} <span *ngIf='spot.length>1' ><span *ngFor='let spot_p of spot'> <span class="mini"> {{spot_p.service_name}} <span style="font-size: 12px; color:#2f2f3f ; text-align: right;">{{spot_p.emplo_name}}</span></span></span></span></span> 
                                </span>
                            </span>
                            <!-- <span class='nada' style="padding: 10px 0px;" *ngIf='results_empl_serv[ind][0].length==0 || results_empl_serv[ind].length!=service.length'>{{employee.name}} non effetua questop tipo di servizi</span> -->
                            <p class='nada' *ngIf='final_spots_displ.length==0'>Siamo spiacenti, nessuna disponibilità in questa data<br>Prova a cambiare data o selezionare un altro collaboratore </p>
                            <!-- <span class='nada' *ngIf='selected_date!="Seleziona data"  && service!=undefined && availableSpots.length==0'>Nessuna disponibilità in questa data</span> -->
                        <!-- -->
                            </span>
                        <!-- </span> -->
                       
                    </span>
                    <div class="close" (click)='closeBooking()'>Annulla</div>
                </span>
            </div>
            <div class="prenota-blue" (click)='book()'>
                    <button style="color:#0061d5" [disabled]='disabled_btn'>prenota</button>
             </div>
        </div>
    </div>

<div class="cont-template">
                <div class="hero-wrap " style="background-image: url('../assets/ezgif.com-gif-maker.jpg');" data-stellar-background-ratio="0.5">
                    <!-- <div style="width: 100vw; height: 100%;background: rgba(#000000,0.8);"> -->

            
                    <div class="container">
                      <div class="row no-gutters slider-text align-items-center justify-content-start" data-scrollax-parent="true">
                          <!-- <a href="https://vimeo.com/45830194" class="icon popup-vimeo d-flex justify-content-center align-items-center">
                              <span class="icon-play"></a>
                          </a> -->
                        <div class="col-md-6 ftco-animate" data-scrollax=" properties: { translateY: '70%' }">
                          <h1 class="mb-5" data-scrollax="properties: { translateY: '30%', opacity: 1.6 }" style="color: #00479d !important;">Dottor Antonello Bartoli</h1>
                          <!-- <div (click)="ser='block'"data-scrollax="properties: { translateY: '30%', opacity: 1.6 }"><div  class="btn btn-primary px-4 py-3">Prenota appuntmamento</div></div> -->
                          <div (click)="showServices()"><div style="z-index: 100;" class="btn btn-primary px-4 py-3">{{button_text}}</div></div>
                        </div>
                      </div>
                    </div>
                  <!-- </div> -->
                </div>
                  <section class="ftco-intro light-blue">
                        <div class="container-wrap">
                            <div class="wrap d-md-flex align-items-end">
                                <div class="info">
                                    <div class="row no-gutters">
                                        <div class="col-md-3 d-flex ftco-animate">
                                            <div class="icon"><span class="icon-phone"></span></div>
                                            <div class="text">
                                                <h3>040 265209</h3>
                                                <p>Telefono</p>
                                            </div>
                                        </div>
                                        <div class="col-md-3 d-flex ftco-animate">
                                            <div class="icon"><span class="icon-my_location"></span></div>
                                            <div class="text">
                                                <h3>Via Pirano 12</h3>
                                                <p>34145 Trieste(TS) Italy</p>
                                            </div>
                                        </div>
                                        <div class="col-md-6 d-flex ftco-animate">
                                            <div class="icon"><span class="icon-clock-o"></span></div>
                                            <div class="text">
                                                <h3>Orario di apertura</h3>
                                                <p>Mar, Mer, Gio, Ven, Sab 
                                                    9:00 - 19:00</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="social pl-md-5 p-4">
                                    <ul class="social-icon">
                              <li class="ftco-animate"><a href="#"><span class="icon-twitter"></span></a></li>
                              <li class="ftco-animate"><a href="#"><span class="icon-facebook"></span></a></li>
                              <li class="ftco-animate"><a href="#"><span class="icon-instagram"></span></a></li>
                            </ul>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section class="ftco-section light-blue">
                            <div class="container">
                                <div class="row justify-content-center mb-4">
                              <div class="col-md-7 heading-section ftco-animate text-center">
                                <h2 class="mb-4">Benvenuto nello studio <span>Bartoli</span></h2>
                                <p class="flip"><span class="deg1"></span><span class="deg2"></span><span class="deg3"></span></p>
                              </div>
                            </div>
                                <div class="row justify-content-center">
                                    <div class="col-md-8 text-center ftco-animate">
                                        <p>Giacomo è un esclusivo salone nella zona sud di Trieste, in via Pirano 12, dall'arredamento elegante e minimal chic che gioca sul contrasto creato dal classico black and white.<br><br>  Alan, il titolare, ha scoperto giovanissimo la passione per il mestiere. Prendendo come punto di riferimento sua mamma, che lo ha poi supportato in tutto il suo percorso formativo tecnico/artistico.<br> <br>  La qualità dei prodotti Davines e Philip Martin's contribuiscono a dare ulteriore prestigio al salone, che mira sempre alla soddisfazione del cliente su cui viene pensato un percorso di bellezza e consulenza minuziosa e personalizzata.</p>
                                    </div>
                                </div>
                            </div>
                    </section>
                    <section class="ftco-section ">
                            <div class="container">
                                    <div class="row justify-content-center mb-5 pb-3 mt-5 pt-5">
                                  <div class="col-md-7 heading-section text-center ftco-animate">
                                    <h2 class="mb-4">Listino servizi &amp; prezzi</h2>
                                    <p class="flip"><span class="deg1"></span><span class="deg2"></span><span class="deg3"></span></p>
                                    <p class="mt-5">I nostri servizi ed listino prezzi</p>
                                  </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="pricing-entry ftco-animate">
                                            <div class="d-flex text align-items-center">
                                                <h3><span>Taglio da uomo</span></h3>
                                                <span class="price">€20.00</span>
                                            </div>
                                            <div class="d-block">
                                                <p>Taglio tradizionale fatto a forbice con tecnica a prisma</p>
                                            </div>
                                        </div>
                                        <div class="pricing-entry ftco-animate">
                                            <div class="d-flex text align-items-center">
                                                <h3><span>Barba accorciata e rifilata</span></h3>
                                                <span class="price">€15.00</span>
                                            </div>
                                            <div class="d-block">
                                                <p>Accorciatura e rifinitura barba a forbice e rasoio elettrico</p>
                                            </div>
                                        </div>
                                        <div class="pricing-entry ftco-animate">
                                            <div class="d-flex text align-items-center">
                                                <h3><span>Colore</span></h3>
                                                <span class="price">€40.00</span>
                                            </div>
                                            <div class="d-block">
                                                <p>Servizio di colorazione capelli</p>
                                            </div>
                                        </div>
                                        <div class="pricing-entry ftco-animate">
                                                <div class="d-flex text align-items-center">
                                                    <h3><span>Colore sensa Ammoniaca</span></h3>
                                                    <span class="price">€42.00</span>
                                                </div>
                                                <div class="d-block">
                                                    <p>Servizio di colorazione capelli senza ammoniaca</p>
                                                </div>
                                            </div>
                                        <div class="pricing-entry ftco-animate">
                                            <div class="d-flex text align-items-center">
                                                <h3><span>Piega</span></h3>
                                                <span class="price">€15.00</span>
                                            </div>
                                            <div class="d-block">
                                                <p>Servizio di piega e styling finale</p>
                                            </div>
                                        </div>
                                        <div class="pricing-entry ftco-animate">
                                                <div class="d-flex text align-items-center">
                                                    <h3><span>Piega con piastra</span></h3>
                                                    <span class="price">€18.00</span>
                                                </div>
                                                <div class="d-block">
                                                    <p>Servizio di piega e styling finale con l'utilizzo di piastra</p>
                                                </div>
                                            </div>
                                        <div class="pricing-entry ftco-animate">
                                                <div class="d-flex text align-items-center">
                                                    <h3><span>Stiraggio Naturale</span></h3>
                                                    <span class="price">€40.00</span>
                                                </div>
                                                <div class="d-block">
                                                    <p>Trattamento con piastra ed prodotti liscianti</p>
                                                </div>
                                            </div>
                                            <div class="pricing-entry ftco-animate">
                                                    <div class="d-flex text align-items-center">
                                                        <h3><span>Flamboyage</span></h3>
                                                        <span class="price">€50.00</span>
                                                    </div>
                                                    <div class="d-block">
                                                        <p>Contrasti eseguiti usando delle pellicole adesive, per un risultato naturale</p>
                                                    </div>
                                                </div>
                                    </div>
                        
                                    <div class="col-md-6">
                                        <div class="pricing-entry ftco-animate">
                                            <div class="d-flex text align-items-center">
                                                <h3><span>Colpi di sole</span></h3>
                                                <span class="price">€55.00</span>
                                            </div>
                                            <div class="d-block">
                                                <p>Decolorazione per capelli con tecnica di colpi di sole</p>
                                            </div>
                                        </div>
                                        <div class="pricing-entry ftco-animate">
                                            <div class="d-flex text align-items-center">
                                                <h3><span>Ondulazione</span></h3>
                                                <span class="price">€40.00</span>
                                            </div>
                                            <div class="d-block">
                                                <p>Trattamento dei capelli che li rende più voluminosi e corposi</p>
                                            </div>
                                        </div>
                                        <div class="pricing-entry ftco-animate">
                                                <div class="d-flex text align-items-center">
                                                    <h3><span>Colpi di Luce</span></h3>
                                                    <span class="price">€35.00</span>
                                                </div>
                                                <div class="d-block">
                                                    <p>Contrasti effetuati a mano libera per dare il vero effetto luce</p>
                                                </div>
                                            </div>
                                        <div class="pricing-entry ftco-animate">
                                                <div class="d-flex text align-items-center">
                                                    <h3><span>Colore + contrasti</span></h3>
                                                    <span class="price">€80.00</span>
                                                </div>
                                                <div class="d-block">
                                                    <p>Color emotion + contrasti dal risultato naturale</p>
                                                </div>
                                            </div>
                                        <div class="pricing-entry ftco-animate">
                                                <div class="d-flex text align-items-center">
                                                    <h3><span>Taglio donna</span></h3>
                                                    <span class="price">€20.00</span>
                                                </div>
                                                <div class="d-block">
                                                        <p>Taglio tradizionale fatto a forbice con tecnica a prisma</p>
                                                </div>
                                            </div>
                                            <div class="pricing-entry ftco-animate">
                                                    <div class="d-flex text align-items-center">
                                                        <h3><span>Shatush</span></h3>
                                                        <span class="price">€40.00</span>
                                                    </div>
                                                    <div class="d-block">
                                                        <p>Cotonamento capelli, e applicazione a pennello del prodotto schiarente.</p>
                                                    </div>
                                                </div>
                                                <div class="pricing-entry ftco-animate">
                                                        <div class="d-flex text align-items-center">
                                                            <h3><span>Shampoo specifico</span></h3>
                                                            <span class="price">€5.00</span>
                                                        </div>
                                                        <div class="d-block">
                                                            <p>In base al tipo di capello, al cliente verrà abbinato lo shampoo corretto.</p>
                                                        </div>
                                                    </div>
                                    </div>
                                </div>
                                </div>
                            </section>
                            <section class="ftco-section ftco-discount img" style="background-image: url(../assets/trim/images/bg_4.jpg);" data-stellar-background-ratio="0.5">
                                <div class="overlay"></div>
                                <div class="container">
                                    <div class="row justify-content-center" data-scrollax-parent="true">
                                        <div class="col-md-7 text-center discount ftco-animate" data-scrollax=" properties: { translateY: '-30%'}">
                                            <h3>Prendi appuntamento online</h3>
                                            <h2 class="mb-4">Tutti i servizi disponibili</h2>
                                            <p class="mb-4" style="color: white;">Senza doverti distubare a prendere il telefono e chiamare, puoi prendere appuntmento direttamente da qui</p>
                                            <div (click)="showServices()" class="btn btn-primary px-4 py-3">{{button_text}}</div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <app-footer></app-footer>
</div>


<div class="cont-reg" [style.display]="register_form">
        <div class="close-cont-reg" (click)='register_form="none"'>  <img src="../assets/icons/closewhite.svg"/></div>
    <div class="register-form" *ngIf="formType=='Register'">
            <h1>prenota</h1>
        <div class="header">
            <div class="active">
                Registrati
            </div>
            <div (click)="formType='Login'">
                Accedi
            </div>
        </div>
        <div class='inputs'>
            <div class="row row-space">
                <div class="col-2-r">
                    <div class="input-group">
                        <label class="label">Nome</label>
                        <input class="input--style-4" [(ngModel)]="first_name" type="text" name="first_name" placeholder="Mario">
                        <p *ngIf='first_name_err!=""'>{{first_name_err}}</p>
                    </div>
                </div>
                <div class="col-2-r">
                    <div class="input-group">
                        <label class="label">Cognome</label>
                        <input class="input--style-4" [(ngModel)]="last_name" type="text" name="last_name" placeholder="Rossi">
                        <p *ngIf='last_name_err!=""'>{{last_name_err}}</p>
                    </div>
                </div>
            </div>
            <div class="row row-space">
                <div class="col-2-r">
                    <div class="input-group">
                        <label class="label">Email</label>
                        <input class="input--style-4" [(ngModel)]="email" type="email" name="email"  placeholder="mario.rossi@email.com">
                        <p *ngIf='email_err!=""'>{{email_err}}</p>
                    </div>
                </div>
                <div class="col-2-r">
                        <div class="input-group">
                            <label class="label">Numero di telefono</label>
                            <input class="input--style-4"[(ngModel)]="phone" type="text" name="phone" placeholder="3312233645">
                            <p *ngIf='phone_err!=""'>{{phone_err}}</p>
                        </div>
                    </div>
            </div>
            <div class="row row-space">
                <div class="col-2-r">
                    <div class="input-group" >
                        <label class="label">Sesso</label>
                        <div class="p-t-10">
                            <label class="radio-container m-r-45">Uomo
                                <input type="radio" [(ngModel)]="sex" value="m"checked="checked" name="gender">
                                <span class="checkmark"></span>
                            </label>
                            <label class="radio-container">Donna
                                <input type="radio" [(ngModel)]="sex" value="w" name="gender">
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-2-r">
                    <div class="input-group">
                        <label class="label">Password</label>
                        <input class="input--style-4" [(ngModel)]="password" type="password" name="password"  placeholder="********">
                        <p *ngIf='password_err!=""'>{{password_err}}</p>
                    </div>
                </div>
            </div>
                <div class="button-reg">  <button (click)='SendBooking("register")'>Conferma prenotazione</button></div>
        </div> 
    </div>
    <div class="login-form" *ngIf="formType=='Login'"> 
            <h1>prenota</h1>
            <div class="header">
                <div  (click)="formType='Register'">
                    Registrati
                </div>
                <div class="active">
                    Accedi
                </div>
            </div>
            <div class='inputs'>
            <p *ngIf='error!=""'>{{error}}</p>
            <label class="label">Email</label>
            <input class="input--style-4"  type="email" [(ngModel)]="email1" name="email" placeholder="mario.rossi@email.com">
            <label class="label">Password</label>
            <input class="input--style-4"  type="password" name="password" [(ngModel)]="password1"  placeholder="********">
                    <div class="button-reg">  <button (click)='SendBooking("login")'>Conferma prenotazione</button></div>
            </div> 
        </div>
</div>
