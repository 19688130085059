<app-header ></app-header>
<div class="cont">
<div>
    <h1>Fisioterapia a Trieste</h1>
    <h2>Lista completa di tutti i fisioterapisti e centri fisioterapici nel comune di Trieste<br><br></h2>
    <div class="no_mob">
    <h3>Fisioterapista</h3>
    <h3>Tecarterapia</h3>
    <h3>Fisioterapia</h3>
    <h3>Riabilitazione</h3>
    <h3>Riabilitazione Sportiva</h3>
    <h3>Fisioterapia bambini</h3>
</div>
</div>
<div class="img-cont col-sm-12">
    <img src='../assets/20944985.svg' title="Fisioterapista a Trieste" alt='Fisioterapista a Trieste'>
</div>
</div>
<div class="list_activities">
    <div class="activity col-md-5 offset-md-1 col-sm-12" *ngFor='let doc of eye_doctors; let ind = index'>
        <div class="img_cont">
            <img *ngIf='doc.img' src='{{doc.img}}' title="{{doc.name}}" alt='{{doc.name}}'>
            <img *ngIf='!doc.img' src='../assets/physical-therapy.svg' title="{{doc.name}}" alt='{{doc.name}}'>
        </div>
        <div class="text_activity" >
            <h2>{{doc.name}}</h2> <img class="img_verified" *ngIf='doc.verified' src='../assets/icons/check.svg'>
            <h4>Fisioterapia - {{doc.address}}</h4>
            <a [routerLink]="doc.url"><button class="book" *ngIf='doc.verified'>Vedi disponibilità</button ></a><a [routerLink]="doc.url"><button class="info">Magg. informazioni</button></a>
        </div>
    </div>

</div>
<app-footer></app-footer>
