import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Notiflix from "notiflix";
import { ApiService } from '../../services/api.service';
@Component({
  selector: 'app-reg-employee',
  templateUrl: './reg-employee.component.html',
  styleUrls: ['./reg-employee.component.scss']
})
export class RegEmployeeComponent implements OnInit {
  
  constructor() { }

  ngOnInit(): void {
  }}
