<body>
    <div class="home">
        <div class="side-menu">
            <div class="menu-home" (click)='goHome()'>Calendario</div>
            <div class="menu-settings" (click)='goSettings()'>Impostazioni</div>
            <div class="menu-data">Area clienti</div>
            <div class="menu-logout" (click)='logout()'> <img src="../assets/icons/logout.svg"/> Esci</div>
        </div> 
        <div class="cont-left">
                <div class="spinner" [style.display]="spin">
                        <div class="double-bounce1"></div>
                        <div class="double-bounce2"></div>
                      </div>
            <div class="topbar">
                <div (click)='goNotifications()'>Notifiche</div>
                <div class="not"  >Clienti</div>
            </div>
                <div id="right-panel" style="margin-top: 8vh;">
                        <!-- /#header -->
                        <!-- Content -->
                        <div class="content">
                                <!-- Animated -->
                                <div class="animated fadeIn">
                <div class="orders">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card">
                                    <div class="card-body">
                                        <h4 class="box-title">I tuoi clienti e i loro dati </h4>
                                    </div>
                                    <div class="card-body--">
                                        <div class="table-stats order-table ov-h">
                                            <table class="table ">
                                                    <thead>
                                                            <tr>
                                                                <th class="serial" style="    border-top-left-radius: 10px;
                                                                border-top-right-radius: 10px;">Cerca <input style="border-radius: 2px; padding: 5px 10px" /> </th>

                                                            </tr>
                                                        </thead>
                                                <thead>
                                                    <tr>
                                                        <th class="serial">Nome e Cognome</th>
                                                        <th>Cellulare</th>
                                                        <th>Credito</th>
                                                        <th>Note</th>
                                                        <th style="padding-left:40px ;">Salva</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                        <tr *ngFor='let client of clients'>
                                                                <td class="serial"><input [(ngModel)]='client.client_name'></td>
                                                                
                                                               
                                                                <td> <input [(ngModel)]='client.phone'></td>
                                                                <td> <input [(ngModel)]='client.credit'></td>
                                                                <td> <input [(ngModel)]='client.note'></td>
                                                                <td>
                                                                    <span class="badge badge-complete" (click)='update(client)'>Aggiorna</span>
                                                                </td>
                                                            </tr>
                                                   
                                                </tbody>
                                            </table>
                                        </div> <!-- /.table-stats -->
                                    </div>
                                </div> <!-- /.card -->
                            </div>  <!-- /.col-lg-8 -->
                        </div>
                           
                            <div class="row">
                                <div class="col-xl-12">
                                    <div class="card" style="height: unset !important;">
                                        <div class="card-body">
                                            <h4 class="box-title">Aggiungi nuovo cliente</h4>
                                        </div>
                                        <div class="card-body--">
                                            <div class="table-stats order-table ov-h">
                                                <table class="table ">
                        
                                <thead>
                                    <tr>
                                        <th class="serial">Nome e Cognome</th>
                                        <th class="avatar">Colore</th>
                                        <th>Lunghezza</th>
                                        <th>Cellulare</th>
                                        <th>Spesa</th>
                                        <th>Ultimo Servizio</th>
                                        <th>Aggiungi</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="serial"><input [(ngModel)]='client_name' placeholder="Mario Rossi"></td>
                                        <td class="serial"><input [(ngModel)]='hair_color' placeholder="Castano"></td>
                                        <td class="serial"><input [(ngModel)]='hair_lenght' placeholder="Capello Lungo"></td>
                                        <td class="serial"><input [(ngModel)]='phone' placeholder="331 2233645"></td>
                                        <td class="serial"><input [(ngModel)]='avarage_expense' placeholder="Spesa media"></td>
                                        <td class="serial"><input [(ngModel)]='last_service' placeholder="Talgio + barba"></td>
                                        <td>
                                            <span class="badge badge-complete" (click)='newClient()'>Aggiungi</span>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                </div>
                                </div>
                                </div>
                                </div>
                            </div>
                           
               
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</body>



   <!-- <div class="container-table100">
                    <h2 style="padding:20px">I dati sui tuoi clienti</h2>
                    <div class="wrap-table100">
                        <div class="table100 ver1 m-b-110">
                            <div class="table100-head">
                                <table>
                                    <thead>
                                        <tr>
                                            <th class=" column1">Cliente</th>
                                            <th class=" column2">Telefono</th>
                                            <th class=" column3">Email</th>
                                            <th class=" column4">Colore</th>
                                            <th class=" column5">Lunghezza </th>
                                            <th class=" column6">Spesa</th>
                                            <th class=" column7">Prodotti</th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
        
                            <div class="table100-body js-pscroll">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td class=" column1">Giacomo Venier</td>
                                            <td class=" column2">331 2233645</td>
                                            <td class=" column3">giacomo.venier@gmail.com</td>
                                            <td class=" column4">Castano</td>
                                            <td class=" column5">Corto</td>
                                            <td class=" column6">Media</td>
                                            <td class=" column7">Shampoo</td>
                                        </tr>
                                        <tr>
                                            <td class=" column1">Giacomo Venier</td>
                                            <td class=" column2">331 2233645</td>
                                            <td class=" column3">giacomo.venier@gmail.com</td>
                                            <td class=" column4">Castano</td>
                                            <td class=" column5">Corto</td>
                                            <td class=" column6">Media</td>
                                            <td class=" column7">Shampoo</td>
                                        </tr>
                                        <tr>
                                                <td class=" column1">Giacomo Venier</td>
                                                <td class=" column2">331 2233645</td>
                                                <td class=" column3">giacomo.venier@gmail.com</td>
                                                <td class=" column4">Castano</td>
                                                <td class=" column5">Corto</td>
                                                <td class=" column6">Media</td>
                                                <td class=" column7">Shampoo</td>
                                            </tr>
                                            <tr>
                                                    <td class=" column1">Giacomo Venier</td>
                                                    <td class=" column2">331 2233645</td>
                                                    <td class=" column3">giacomo.venier@gmail.com</td>
                                                    <td class=" column4">Castano</td>
                                                    <td class=" column5">Corto</td>
                                                    <td class=" column6">Media</td>
                                                    <td class=" column7">Shampoo</td>
                                                </tr>
                                                <tr>
                                                        <td class=" column1">Giacomo Venier</td>
                                                        <td class=" column2">331 2233645</td>
                                                        <td class=" column3">giacomo.venier@gmail.com</td>
                                                        <td class=" column4">Castano</td>
                                                        <td class=" column5">Corto</td>
                                                        <td class=" column6">Media</td>
                                                        <td class=" column7">Shampoo</td>
                                                    </tr>
                                                    <tr>
                                                            <td class=" column1">Giacomo Venier</td>
                                                            <td class=" column2">331 2233645</td>
                                                            <td class=" column3">giacomo.venier@gmail.com</td>
                                                            <td class=" column4">Castano</td>
                                                            <td class=" column5">Corto</td>
                                                            <td class=" column6">Media</td>
                                                            <td class=" column7">Shampoo</td>
                                                        </tr>
                                                        <tr>
                                                                <td class=" column1">Giacomo Venier</td>
                                                                <td class=" column2">331 2233645</td>
                                                                <td class=" column3">giacomo.venier@gmail.com</td>
                                                                <td class=" column4">Castano</td>
                                                                <td class=" column5">Corto</td>
                                                                <td class=" column6">Media</td>
                                                                <td class=" column7">Shampoo</td>
                                                            </tr>
                                                            <tr>
                                                                    <td class=" column1">Giacomo Venier</td>
                                                                    <td class=" column2">331 2233645</td>
                                                                    <td class=" column3">giacomo.venier@gmail.com</td>
                                                                    <td class=" column4">Castano</td>
                                                                    <td class=" column5">Corto</td>
                                                                    <td class=" column6">Media</td>
                                                                    <td class=" column7">Shampoo</td>
                                                                </tr>
                                                                <tr>
                                                                        <td class=" column1">Giacomo Venier</td>
                                                                        <td class=" column2">331 2233645</td>
                                                                        <td class=" column3">giacomo.venier@gmail.com</td>
                                                                        <td class=" column4">Castano</td>
                                                                        <td class=" column5">Corto</td>
                                                                        <td class=" column6">Media</td>
                                                                        <td class=" column7">Shampoo</td>
                                                                    </tr>
                                                                    <tr>
                                                                            <td class=" column1">Giacomo Venier</td>
                                                                            <td class=" column2">331 2233645</td>
                                                                            <td class=" column3">giacomo.venier@gmail.com</td>
                                                                            <td class=" column4">Castano</td>
                                                                            <td class=" column5">Corto</td>
                                                                            <td class=" column6">Media</td>
                                                                            <td class=" column7">Shampoo</td>
                                                                        </tr>
                                                                        <tr>
                                                                                <td class=" column1">Giacomo Venier</td>
                                                                                <td class=" column2">331 2233645</td>
                                                                                <td class=" column3">giacomo.venier@gmail.com</td>
                                                                                <td class=" column4">Castano</td>
                                                                                <td class=" column5">Corto</td>
                                                                                <td class=" column6">Media</td>
                                                                                <td class=" column7">Shampoo</td>
                                                                            </tr>
                                                                            <tr>
                                                                                    <td class=" column1">Giacomo Venier</td>
                                                                                    <td class=" column2">331 2233645</td>
                                                                                    <td class=" column3">giacomo.venier@gmail.com</td>
                                                                                    <td class=" column4">Castano</td>
                                                                                    <td class=" column5">Corto</td>
                                                                                    <td class=" column6">Media</td>
                                                                                    <td class=" column7">Shampoo</td>
                                                                                </tr><tr>
                                                                                        <td class=" column1">Giacomo Venier</td>
                                                                                        <td class=" column2">331 2233645</td>
                                                                                        <td class=" column3">giacomo.venier@gmail.com</td>
                                                                                        <td class=" column4">Castano</td>
                                                                                        <td class=" column5">Corto</td>
                                                                                        <td class=" column6">Media</td>
                                                                                        <td class=" column7">Shampoo</td>
                                                                                    </tr>

                                                                                    <tr>
                                            <td class=" column1">Giacomo Venier</td>
                                            <td class=" column2">331 2233645</td>
                                            <td class=" column3">giacomo.venier@gmail.com</td>
                                            <td class=" column4">Castano</td>
                                            <td class=" column5">Corto</td>
                                            <td class=" column6">Media</td>
                                            <td class=" column7">Shampoo</td>
                                        </tr>
                                        <tr>
                                            <td class=" column1">Giacomo Venier</td>
                                            <td class=" column2">331 2233645</td>
                                            <td class=" column3">giacomo.venier@gmail.com</td>
                                            <td class=" column4">Castano</td>
                                            <td class=" column5">Corto</td>
                                            <td class=" column6">Media</td>
                                            <td class=" column7">Shampoo</td>
                                        </tr>
                                        <tr>
                                                <td class=" column1">Giacomo Venier</td>
                                                <td class=" column2">331 2233645</td>
                                                <td class=" column3">giacomo.venier@gmail.com</td>
                                                <td class=" column4">Castano</td>
                                                <td class=" column5">Corto</td>
                                                <td class=" column6">Media</td>
                                                <td class=" column7">Shampoo</td>
                                            </tr>
                                            <tr>
                                                    <td class=" column1">Giacomo Venier</td>
                                                    <td class=" column2">331 2233645</td>
                                                    <td class=" column3">giacomo.venier@gmail.com</td>
                                                    <td class=" column4">Castano</td>
                                                    <td class=" column5">Corto</td>
                                                    <td class=" column6">Media</td>
                                                    <td class=" column7">Shampoo</td>
                                                </tr>
                                                <tr>
                                                        <td class=" column1">Giacomo Venier</td>
                                                        <td class=" column2">331 2233645</td>
                                                        <td class=" column3">giacomo.venier@gmail.com</td>
                                                        <td class=" column4">Castano</td>
                                                        <td class=" column5">Corto</td>
                                                        <td class=" column6">Media</td>
                                                        <td class=" column7">Shampoo</td>
                                                    </tr>
                                                    <tr>
                                                            <td class=" column1">Giacomo Venier</td>
                                                            <td class=" column2">331 2233645</td>
                                                            <td class=" column3">giacomo.venier@gmail.com</td>
                                                            <td class=" column4">Castano</td>
                                                            <td class=" column5">Corto</td>
                                                            <td class=" column6">Media</td>
                                                            <td class=" column7">Shampoo</td>
                                                        </tr>
                                                        <tr>
                                                                <td class=" column1">Giacomo Venier</td>
                                                                <td class=" column2">331 2233645</td>
                                                                <td class=" column3">giacomo.venier@gmail.com</td>
                                                                <td class=" column4">Castano</td>
                                                                <td class=" column5">Corto</td>
                                                                <td class=" column6">Media</td>
                                                                <td class=" column7">Shampoo</td>
                                                            </tr>

                                                            <tr>
                                            <td class=" column1">Giacomo Venier</td>
                                            <td class=" column2">331 2233645</td>
                                            <td class=" column3">giacomo.venier@gmail.com</td>
                                            <td class=" column4">Castano</td>
                                            <td class=" column5">Corto</td>
                                            <td class=" column6">Media</td>
                                            <td class=" column7">Shampoo</td>
                                        </tr>
                                       
                                    </tbody>
                                </table>
                            </div>
                        </div>         
                    </div>
                </div> -->