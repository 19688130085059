<div class="cont">
    <h2>Prenota i servizi di Trieste online.<br>  </h2>
    <br>
    <br>  <br>

    <h4>I vantaggi</h4> 
  <div style="display: block; position: relative; margin-top:10px">

 <div class='adv' [style.left]='left_1' [style.visibility]='vis_1'><p>Da oggi puoi prenotare online. <br>In meno di 30 secondi</p></div>
 <div class='adv' [style.left]='left_2' [style.visibility]='vis_2'><p> Vedrai tutte le date e gli orari disponibili. {{emoji_love}}</p></div>
 <div class='adv' [style.left]='left_3' [style.visibility]='vis_3'><p> Riceverai un promemoria per ricordarti dell'appuntamento.</p></div>
 <div class='adv' [style.left]='left_4' [style.visibility]='vis_4'><p> Potrai prenotare a qualsiasi ora del giorno, o della notte {{emoji_devil}}</p></div>
  </div>
 <div style=" margin-top:250px; position: relative;">
    <h4>Inizia subito</h4>
 <button class='button_blue' (click)='getApp()'>Scarica l'app</button>
</div>
</div>