<app-header></app-header>
<section class="content">
<h1>Come utilizzare Prenota</h1>

<h3>Se sei un nuovo cliente:</h3>

<p><b>1 -</b>  Vai nella sezione <a href='https://prenota.cc/business'>business</a> e premi il tasto “Inizia la prova gratuita”.</p>
<br>
<p><b>2 -</b> Crea il tuo account. Utilizza l’ <b class="color">email</b> e il <b class="color">numero di telefono</b> che preferisci, non saranno visibili ai tuoi clienti.</p>
<br>
<p><b>3 -</b> Inserisci i dati della <b class="color">tua attività.</b></p>
<br>
<p><b>4 -</b> Aggiungi gli <b class="color">orari generali del tuo negozio</b>. Gli orari singoli per ogni collaboratore saranno aggiunti in seguito.<br>
	<span class='small-t'>[Se non hai un orario continuato, seleziona l’opzione “2 		turni”.
	Così puoi aggiungere, per esempio, la tua pausa pranzo.]</span></p>
<br>
<p><b>Fine!</b> La tua agenda è stata creata</p>

<h3>Per i clienti che hanno già creato l’agenda:</h3>

<p><b>6 -</b> Personalizza l’ agenda in base alle tue esigenze. A sinistra vedrai il tasto <a href='https://prenota.cc/settings'>“Impostazioni”</a>. Da lì, nella sezione <b class="color">“Tipo di attività”</b> potrai selezionare il tipo di attività che hai. <b class="color"> I clienti ti troveranno</b> nell’app e nel sito <b class="color">nella categoria che selezioni.</b></p>
<br>
<p><b>7 -</b> Nella pagina “Impostazioni” stessa pagina, troverai il tasto <a href='https://prenota.cc/settings'>“I tuoi servizi”</a>. <!-- Lì, potrai trovare dei servizi standard che sono stati aggiunti in base al tipo di attività che hai selezionato.--> Da questa sezione, potrai aggiungerne, modificare o rimuovere i <b class="color">servizi che offri al pubblico.</b>
<br>
<span class='small-t'>[Quando vuoi aggiungere un servizio, tra i dati richiesti vedrai “Max”. Questo valore equivale al massimo numero di volte in cui la tua attività riesce ad offrire il servizio (che stai aggiungendo) nello stesso momento. (Esempio: se uno studio medico ha tre medici, ma solo un macchinario per una prova da sforzo, il valore “Max” per il servizio Prova da Sforzo sarà uno. Se lo studio ha tre stanze in cui i tre medici possono fare una visita medica, il valore “Max” di visita medica sarà tre.]</span></p>
<br>
<p><b>8 -</b> Sempre nella pagina “Impostazioni”, in alto a destra troverai <a href='https://prenota.cc/settings/employees'>“Collaboratori”</a>. Cliccaci sopra e da lì potrai gestire tutte le informazioni su chi lavora nella tua attività. Puoi aggiungere nuovi collaboratori in maniera molto semplice, gestire i loro orari e inserire i servizi che loro possono performare.</p>
<br>
<p><b>9 -</b>Di default, ogni collaboratore potrà performare ogni servizio offerto dal negozio. Dalla sezione <a href='https://prenota.cc/settings/employees'>“Servizi Collaboratore”</a>, potrai  personalizzare i servizi dei tuoi collaboratori. <br><span class='small-t'>[Esempio: Giovanni, che lavora in un salone di parrucchieri, farà solo tagli di capelli maschili e non farà colori]</span>.</p><br><br>

<p><b>RICORDATI SEMPRE DI SALVARE LE MODIFICHE IN ALTO A DESTRA!</b></p>
<br>

<h3>Per i clienti che hanno già finito di personalizzare l’agenda:</h3>

<p><b>10 -</b> <b class="color">Scarica l’app “Prenota Business”</b> su <a href='https://apps.apple.com/it/app/prenota-business/id1520379243?l=en'>App Store</a> o <a href='http://play.google.com/store/apps/details?id=io.prenota.business'>Google Play</a>per avere l’agenda della tua attività sempre a portata di mano! Da quest’app potrai <b class="color">gestire gli appuntamenti dal telefono</b>. Inoltre cliccando sul tuo nome in alto, potrai accedere all’agenda di ogni tuo collaboratore.
Inoltre potrai accedere ad uno storico delle prenotazioni effettuate, così da conoscere meglio i tuoi clienti.</p>

<p><b>11 -</b>Fai scaricare ai tuoi <b class="color">clienti l’ app “Prenota!”</b> da <a href='https://apps.apple.com/it/app/prenota-business/id1523525291?l=en'>App Store</a> e <a href='http://play.google.com/store/apps/details?id=io.prenota.client'>Google Play</a> per dargli la possibilità di prenotare un appuntamento in qualche secondo senza doverti chiamare o venire a trovare nella tua attività. Potrai utilizzare i biglietti da visita che ti forniremo, dato che avranno il QR Code necessario per arrivare direttamente all’app sui vari store. <b class="color">Sii pronto ad accogliere i nuovi clienti portati da Prenota!</b></p>

<h3>Contattaci quando preferisci se:</h3>

<p><b>-</b> Se la tua attività necessita di un tempo di pausa dalle prenotazioni a causa ferie malattia o qualsiasi cosa.</p>

<p><b>-</b> Se dovessi avere qualunque dubbio</p>

<p><b>-</b> Se tu fossi interessato ad offrire la possibilità ai tuoi clienti di prenotarti online dal tuo sito web o da qualunque pagina social (Instagram, Facebook).</p>

</section>
<app-footer></app-footer>