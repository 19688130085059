
<app-header></app-header>
<div class="cont">
    <div class="main_data">
    <div class="img-cont">
        <img src='../assets/nail-polish.svg'>
    </div>
    <div class="text_cont">
        <h1>{{doc.name}}</h1>
        <h2>Unghie a Trieste</h2>
        <h3>{{doc.address}}</h3>
    </div>
    </div>
    <div class="body_under">
    <p>
       <span>Al momento non è prenotabile online!</span> <br> <br>
        Sarebbe comodo poter prenotare a qualsiasi ora del giorno senza dover chiamare?<br> Consiglia <b>{{doc.name}}</b> di utilizzare Prenota,<b> è gratuito</b>.
</p> 
        <button class="phone-number" (click)='displayPhone()'>{{phone}}</button>
</div>

    <div class="list_activities">
        <p >Se preferisci prenotare in qualche secondo senza chiamare:</p>
        <div class="activity col-md-5 offset-md-1 col-sm-12" >
            <div class="img_cont">
    
                <img src='https://firebasestorage.googleapis.com/v0/b/prenota-d8fae.appspot.com/o/WhatsApp%20Image%202021-05-04%20at%2009.47.00.jpeg?alt=media&token=bcaaf4c6-fd2a-4cdc-b1d6-783f30805665' title="MS Nails" alt='MS Nails'>
            </div>
            <div class="text_activity" >
                <h2>MS Nails</h2> <img class="img_verified"  src='../assets/icons/check.svg'>
                <h4>Unghie - Via Molino a Vento 26/d</h4>
                <a [routerLink]="['/appuntamento/msnails']"><button class="book" >Vedi disponibilità</button ></a><a [routerLink]="['/appuntamento/msnails']"><button class="info">Magg. informazioni</button></a>
            </div>
        </div>
    

</div>
<div class="link_all">
    <a [routerLink]="['/unghie/trieste']" ><button class="all_back_link">Mostra tutti gli studi di unghie a Trieste</button></a>
</div>




<div class="mapouter" id='map_cont'>
   
    
    

    </div>
  </div>

<app-footer></app-footer>  <style>.mapouter{text-align:left;height:250px;width:30vw;}
    
    .gmap_canvas {overflow:hidden;background:none!important;height:250px;width:30vw;}



.mapouter{
    position: absolute; top: 50px;right: 50px;
}

.mapouter h3{
    border-radius: 10px;
    overflow: hidden;
   font-size: 21px;
   color: #424242;
   line-height: 40px;
   margin: 0px;
}
</style>