
<app-header></app-header>
<div class="cont">
    <div class="main_data">
    <div class="img-cont">
        <img src='../assets/tennis-ball.svg' title="{{doc.name}}" alt='{{doc.name}}'>
    </div>
    <div class="text_cont">
        <h1>{{doc.name}}</h1>
        <h2>Campo da tennis a Trieste</h2>
        <h3>{{doc.address}}</h3>
    </div>
    </div>
    <div class="body_under">
    <p>
       <span>Al momento non è prenotabile online!</span> <br> <br>
        Sarebbe comodo poter prenotare a qualsiasi ora del giorno senza dover chiamare?<br> Consiglia al <b>{{doc.name}}</b> di utilizzare Prenota, <b>è gratuito</b>.
</p> 
        <button class="phone-number" (click)='displayPhone()'>{{phone}}</button>
</div>

    <div class="list_activities">
        <p >Se preferisci prenotare in qualche secondo senza chiamare:</p>
        <div  class="activity col-md-5 offset-md-1 col-sm-12"><div  class="img_cont"><img  src="https://firebasestorage.googleapis.com/v0/b/prenota-d8fae.appspot.com/o/nuovo%20copy.jpg?alt=media&amp;token=5c103439-263b-4825-ae28-dfbc0ae5332f" title="Circolo Tennis Grignano" alt="Circolo Tennis Grignano">
           
         
            
         </div><div  class="text_activity"><h2 >Circolo Tennis Grignano</h2><img  src="../assets/icons/check.svg" class="img_verified">
            
         <h4 >Campo da tennis - Via Junker 8, Trieste (TS)</h4>
         <a  [routerLink]="['/appuntamento/Tennis_Grignano']"><button  class="book">Vedi disponibilità</button>          </a>
         <a  [routerLink]="['/appuntamento/Tennis_Grignano']" ><button  class="info" >Magg. informazioni</button></a></div></div>
        
</div>
<div class="link_all">
    <a [routerLink]="['/campi_da_tennis/trieste']" ><button class="all_back_link">Mostra tutti i campi da tennis a Trieste</button></a>
</div>




<div class="mapouter" id='map_cont'>
   
    
    

    </div>
  </div>

<app-footer></app-footer>  <style>.mapouter{text-align:left;height:250px;width:30vw;}
    
    .gmap_canvas {overflow:hidden;background:none!important;height:250px;width:30vw;}



.mapouter{
    position: absolute; top: 50px;right: 50px;
}

.mapouter h3{
    border-radius: 10px;
    overflow: hidden;
   font-size: 21px;
   color: #424242;
   line-height: 40px;
   margin: 0px;
}
</style>