<app-header></app-header>
<section style="display: block; position: relative; margin-top:90px; padding: 40px;">
<h1>Pagina non trovata</h1>
<section class="error-container">
  <span class="four"><span class="screen-reader-text">4</span></span>
  <span class="zero"><span class="screen-reader-text">0</span></span>
  <span class="four"><span class="screen-reader-text">4</span></span>
</section>
<div class="link-container">
    <!-- <a  (click)='goHome()' class="more-link">Vai alla home</a> -->
    <a  (click)='goBooking()' class="more-link1">Effettua una prenotazione</a>
    <a  (click)='goBusiness()' class="more-link">Vai alla sezione business</a>
  
  </div>
</section>
<app-footer></app-footer>