




      <section class="head-45">
  <app-header></app-header>
      </section>

  <section id="hero" class="d-flex align-items-center">

    <div class="container">
      <div class="row">
        <div class="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
          <h1>L' agenda online di Trieste</h1>
          <h2><br>Aggiungi nuove prenotazioni con un solo click.<br> <br>Permetti ai tuoi clienti di prenotare direttamente dall' app mobile o dal tuo sito.<br> <br>Gestisci i tuoi appuntamenti online, da telefono o pc. </h2>
          <div><a class="more-info scrollto"  (click)="navigateToSection('services')">Scopri di più</a> <a (click)='goResiter()'class="btn-get-started scrollto">{{call_to_act}}</a></div>

        </div>
        <div class="col-lg-6 order-1 order-lg-2 hero-img">
          <img src="../assets/butterfly/assets/img/hero-img.png" class="img-fluid" alt="">
        </div>
      </div>
    </div>

  </section><!-- End Hero -->

  <main id="main">
    <section id="services" class="services ">
      <div class="container">

        <div class="section-title">
          <h2>Perchè usare questa agenda?</h2>
          <!-- <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p> -->
        </div>

        <div class="row">
          <div class="col-lg-4 col-md-6">
            <div class="icon-box">
              <div class="icon"><i class="las la-calendar" style="color: #0061d5;"></i></div>
              <h4 class="title"><a href="">Agenda</a></h4>
              <p class="description">Il nostro obiettivo è offire un' agenda <b>facile</b> da usare. <br> Basta un solo click per creare un appuntamento.</p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="icon-box">
              <div class="icon"><i class="las la-laptop" style="color: #0061d5;"></i></div>
              <h4 class="title"><a href="">Prenotazioni Online</a></h4>
              <p class="description">I tuoi <b>clienti possono prenotare direttamente dall'app o dal tuo sito</b>, a qualsiasi ora e senza doverti contattare.</p>
            </div>
          </div>

          <div class="col-lg-4 col-md-6" data-wow-delay="0.1s">
            <div class="icon-box">
              <div class="icon"><i class=" las la-globe-americas" style="color: #0061d5;"></i></div>
              <h4 class="title"><a href="">Accessibile Ovunque</a></h4>
              <p class="description">Puoi accedere alla tua agenda online <b>in qualsiasi momento</b>. Indifferentemente da dove ti trovi.<br> <br></p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6" data-wow-delay="0.1s">
            <div class="icon-box">
              <div class="icon"><i class="las la-search" style="color:#0061d5;"></i></div>
              <h4 class="title"><a href="">Trova Nuovi Clienti</a></h4>
              <p class="description">La tua presenza sulla nostra piattaforma <b>ti porterà nuovi clienti</b> che preferiscono prenotare in maniera moderna.</p>
            </div>
          </div>

          <div class="col-lg-4 col-md-6" data-wow-delay="0.2s">
            <div class="icon-box">
              <div class="icon"><i class="la  la-send-o" style="color: #0061d5;"></i></div>
              <h4 class="title"><a href="">Flessibile</a></h4>
              <p class="description"> La nostra <b>agenda si adatta a te</b>. Che tu sia un libero professionista o che il tuo team sia numeroso, abbiamo la soluzione per te.</p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6" data-wow-delay="0.2s">
            <div class="icon-box">
              <div class="icon"><i class="las la-lock" style="color: #0061d5;"></i></div>
              <h4 class="title"><a href="">Sicurezza & Privacy</a></h4>
              <p class="description">La tua agenda è protetta. I tuoi <b>dati sono criptati e salvati in modo sicuro</b>. Non ti capiterà più di perdere l'agenda.</p>

            </div>
          </div>
        </div>

      </div>
    </section>
    <!-- ======= About Section ======= -->
    <section id="about" class="about">
      <div class="container">
        <div class="section-title">
          <h2>L'agenda </h2>
        </div>

        <div class="row">
          <div class="col-xl-12 d-flex justify-content-center video-box align-items-stretch">
            <div class='screen-app'><img class="fill_cal" src="../assets/Screenshot 2021-01-22 at 15.47.10.png"/></div>
          </div>

          <div style='text-align: center !important;' class="col-xl-12 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5">
            <h3>L'agenda è semplice e pulita</h3>
            <p class="p-style"> La <b>semplicità</b> è la nostra priorità.  Per creare un appuntamento ti <b>basta un click</b>.<br>
                Quando un <b>cliente prenota dall'app</b>, l'appuntamento viene aggiunto automaticamente alla tua agenda.<br>
                <b> Il cliente dall’app vedrà solo i tuoi posti liberi</b>, dunque non avrai bisogno di confermare la tua disponibilità o spostare appuntamenti già fissati.
            </p>
            <div><a class="btn-get-started scrollto"  href="../../assets/Info.pdf" target="_blank">Maggiori informazioni</a></div>
          </div>

        </div>
      </div>
    </section>
    <!-- End About Section -->

    <!-- ======= Counts Section ======= -->
    <section id="counts" class="counts">
       <div class="container">
        <div class="section-title">
            <h2>Prezzo</h2>
            <div  class="col-xl-12 site">
                <p class="site">Ti serve anche un sito dal <b>design moderno e professionale</b>? </p>
                 <div class="switch-conts">
                <label class="switch">
                      <input type="checkbox" [checked]="make_website" (change)="website()">
                      <span class="slider round"></span>
                    </label>
                  </div>
               </div>
        </div>
        <app-subscription [price_SMALL]=price_SMALL [price_MEDIUM]=price_MEDIUM></app-subscription>
        <div style='text-align: center !important;' class="col-xl-12 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5">
          <h3>Vuoi avere anche un sito professionale per te o la tua attivà?</h3>
          <p class='p-style'>Sei interessato ad avere un sito dal <b>design moderno</b>, accessibile sia da computer che da cellulare? Ti offriamo <b>il prezzo più conveniente sul mercato</b>!</p>
         <div style='text-align: center !important;' class="col-xl-12">
        Ricevi il sito professionale <b>a soli 19€</b> al mese
            <!-- <label class="switch">
                <input type="checkbox" [checked]="make_website" (change)="website()">
                <span class="slider round"></span>
              </label> -->
         </div>
        </div>
       </div>
      <!-- <div class="container">

        <div class="text-center title">
          <h3>Prezzo </h3>
          <p>Il nostro prezzo è basso chiaro e senza sorprese!</p>
        </div>

        <div class="row counters">

          <div class="col-lg-3 col-6 text-center">
            <span data-toggle="counter-up">1°</span>
            <p>Mese gratuito</p>
          </div>

          <div class="col-lg-3 col-6 text-center">
            <span data-toggle="counter-up">10€</span>
            <p>al mese</p>
          </div>

          <div class="col-lg-3 col-6 text-center">
            <span data-toggle="counter-up">0€</span>
            <p>Costi di iscrizione</p>
          </div>

          <div class="col-lg-3 col-6 text-center">
            <span data-toggle="counter-up">0€</span>
            <p>Cancellazione gratuita</p>
          </div>

        </div>

      </div> -->
    </section>
    <!-- End Counts Section -->

    <!-- ======= Clients Section ======= -->
    <!-- <section id="clients" class="clients">
      <div class="container">

        <div class="row no-gutters clients-wrap clearfix wow fadeInUp">

          <div class="col-lg-3 col-md-4 col-6">
            <div class="client-logo">
              <img src="../assets/butterfly/assets/img/clients/client-1.png" class="img-fluid" alt="">
            </div>
          </div>

          <div class="col-lg-3 col-md-4 col-6">
            <div class="client-logo">
              <img src="../assets/butterfly/assets/img/clients/client-2.png" class="img-fluid" alt="">
            </div>
          </div>

          <div class="col-lg-3 col-md-4 col-6">
            <div class="client-logo">
              <img src="../assets/butterfly/assets/img/clients/client-3.png" class="img-fluid" alt="">
            </div>
          </div>

          <div class="col-lg-3 col-md-4 col-6">
            <div class="client-logo">
              <img src="../assets/butterfly/assets/img/clients/client-4.png" class="img-fluid" alt="">
            </div>
          </div>

          <div class="col-lg-3 col-md-4 col-6">
            <div class="client-logo">
              <img src="../assets/butterfly/assets/img/clients/client-5.png" class="img-fluid" alt="">
            </div>
          </div>

          <div class="col-lg-3 col-md-4 col-6">
            <div class="client-logo">
              <img src="../assets/butterfly/assets/img/clients/client-6.png" class="img-fluid" alt="">
            </div>
          </div>

          <div class="col-lg-3 col-md-4 col-6">
            <div class="client-logo">
              <img src="../assets/butterfly/assets/img/clients/client-7.png" class="img-fluid" alt="">
            </div>
          </div>

          <div class="col-lg-3 col-md-4 col-6">
            <div class="client-logo">
              <img src="../assets/butterfly/assets/img/clients/client-8.png" class="img-fluid" alt="">
            </div>
          </div>

        </div>

      </div>
    </section>  -->
    <!--End Clients Section -->

    <!-- ======= Services Section ======= -->
   <!-- End Services Section -->

    <!-- ======= Portfolio Section ======= -->
    <!-- <section id="portfolio" class="portfolio">
      <div class="container">

        <div class="section-title">
          <h2>Portfolio</h2>
          <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
        </div>

        <div class="row">
          <div class="col-lg-12 d-flex justify-content-center">
            <ul id="portfolio-flters">
              <li data-filter="*" class="filter-active">All</li>
              <li data-filter=".filter-app">App</li>
              <li data-filter=".filter-card">Card</li>
              <li data-filter=".filter-web">Web</li>
            </ul>
          </div>
        </div>

        <div class="row portfolio-container">

          <div class="col-lg-4 col-md-6 portfolio-item filter-app">
            <div class="portfolio-wrap">
              <img src="../assets/butterfly/assets/img/portfolio/portfolio-1.jpg" class="img-fluid" alt="">
              <div class="portfolio-info">
                <h4>App 1</h4>
                <p>App</p>
                <div class="portfolio-links">
                  <a href="../assets/butterfly/assets/img/portfolio/portfolio-1.jpg" data-gall="portfolioGallery" class="venobox" title="App 1"><i class="bx bx-plus"></i></a>
                  <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-web">
            <div class="portfolio-wrap">
              <img src="../assets/butterfly/assets/img/portfolio/portfolio-2.jpg" class="img-fluid" alt="">
              <div class="portfolio-info">
                <h4>Web 3</h4>
                <p>Web</p>
                <div class="portfolio-links">
                  <a href="../assets/butterfly/assets/img/portfolio/portfolio-2.jpg" data-gall="portfolioGallery" class="venobox" title="Web 3"><i class="bx bx-plus"></i></a>
                  <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-app">
            <div class="portfolio-wrap">
              <img src="../assets/butterfly/assets/img/portfolio/portfolio-3.jpg" class="img-fluid" alt="">
              <div class="portfolio-info">
                <h4>App 2</h4>
                <p>App</p>
                <div class="portfolio-links">
                  <a href="../assets/butterfly/assets/img/portfolio/portfolio-3.jpg" data-gall="portfolioGallery" class="venobox" title="App 2"><i class="bx bx-plus"></i></a>
                  <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-card">
            <div class="portfolio-wrap">
              <img src="../assets/butterfly/assets/img/portfolio/portfolio-4.jpg" class="img-fluid" alt="">
              <div class="portfolio-info">
                <h4>Card 2</h4>
                <p>Card</p>
                <div class="portfolio-links">
                  <a href="../assets/butterfly/assets/img/portfolio/portfolio-4.jpg" data-gall="portfolioGallery" class="venobox" title="Card 2"><i class="bx bx-plus"></i></a>
                  <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-web">
            <div class="portfolio-wrap">
              <img src="../assets/butterfly/assets/img/portfolio/portfolio-5.jpg" class="img-fluid" alt="">
              <div class="portfolio-info">
                <h4>Web 2</h4>
                <p>Web</p>
                <div class="portfolio-links">
                  <a href="../assets/butterfly/assets/img/portfolio/portfolio-5.jpg" data-gall="portfolioGallery" class="venobox" title="Web 2"><i class="bx bx-plus"></i></a>
                  <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-app">
            <div class="portfolio-wrap">
              <img src="../assets/butterfly/assets/img/portfolio/portfolio-6.jpg" class="img-fluid" alt="">
              <div class="portfolio-info">
                <h4>App 3</h4>
                <p>App</p>
                <div class="portfolio-links">
                  <a href="../assets/butterfly/assets/img/portfolio/portfolio-6.jpg" data-gall="portfolioGallery" class="venobox" title="App 3"><i class="bx bx-plus"></i></a>
                  <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-card">
            <div class="portfolio-wrap">
              <img src="../assets/butterfly/assets/img/portfolio/portfolio-7.jpg" class="img-fluid" alt="">
              <div class="portfolio-info">
                <h4>Card 1</h4>
                <p>Card</p>
                <div class="portfolio-links">
                  <a href="../assets/butterfly/assets/img/portfolio/portfolio-7.jpg" data-gall="portfolioGallery" class="venobox" title="Card 1"><i class="bx bx-plus"></i></a>
                  <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-card">
            <div class="portfolio-wrap">
              <img src="../assets/butterfly/assets/img/portfolio/portfolio-8.jpg" class="img-fluid" alt="">
              <div class="portfolio-info">
                <h4>Card 3</h4>
                <p>Card</p>
                <div class="portfolio-links">
                  <a href="../assets/butterfly/assets/img/portfolio/portfolio-8.jpg" data-gall="portfolioGallery" class="venobox" title="Card 3"><i class="bx bx-plus"></i></a>
                  <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-web">
            <div class="portfolio-wrap">
              <img src="../assets/butterfly/assets/img/portfolio/portfolio-9.jpg" class="img-fluid" alt="">
              <div class="portfolio-info">
                <h4>Web 3</h4>
                <p>Web</p>
                <div class="portfolio-links">
                  <a href="../assets/butterfly/assets/img/portfolio/portfolio-9.jpg" data-gall="portfolioGallery" class="venobox" title="Web 3"><i class="bx bx-plus"></i></a>
                  <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section> -->
    <!-- End Portfolio Section -->

    <!-- ======= Testimonials Section ======= -->
    <!-- <section id="testimonials" class="testimonials">
      <div class="container">

        <div class="owl-carousel testimonials-carousel">

          <div class="testimonial-item">
            <img src="../assets/butterfly/assets/img/testimonials/testimonials-1.jpg" class="testimonial-img" alt="">
            <h3>Saul Goodman</h3>
            <h4>Ceo &amp; Founder</h4>
            <p>
              <i class="bx bxs-quote-alt-left quote-icon-left"></i>
              Proin iaculis purus consequat sem cure digni ssim donec porttitora entum suscipit rhoncus. Accusantium quam, ultricies eget id, aliquam eget nibh et. Maecen aliquam, risus at semper.
              <i class="bx bxs-quote-alt-right quote-icon-right"></i>
            </p>
          </div>

          <div class="testimonial-item">
            <img src="../assets/butterfly/assets/img/testimonials/testimonials-2.jpg" class="testimonial-img" alt="">
            <h3>Sara Wilsson</h3>
            <h4>Designer</h4>
            <p>
              <i class="bx bxs-quote-alt-left quote-icon-left"></i>
              Export tempor illum tamen malis malis eram quae irure esse labore quem cillum quid cillum eram malis quorum velit fore eram velit sunt aliqua noster fugiat irure amet legam anim culpa.
              <i class="bx bxs-quote-alt-right quote-icon-right"></i>
            </p>
          </div>

          <div class="testimonial-item">
            <img src="../assets/butterfly/assets/img/testimonials/testimonials-3.jpg" class="testimonial-img" alt="">
            <h3>Jena Karlis</h3>
            <h4>Store Owner</h4>
            <p>
              <i class="bx bxs-quote-alt-left quote-icon-left"></i>
              Enim nisi quem export duis labore cillum quae magna enim sint quorum nulla quem veniam duis minim tempor labore quem eram duis noster aute amet eram fore quis sint minim.
              <i class="bx bxs-quote-alt-right quote-icon-right"></i>
            </p>
          </div>

          <div class="testimonial-item">
            <img src="../assets/butterfly/assets/img/testimonials/testimonials-4.jpg" class="testimonial-img" alt="">
            <h3>Matt Brandon</h3>
            <h4>Freelancer</h4>
            <p>
              <i class="bx bxs-quote-alt-left quote-icon-left"></i>
              Fugiat enim eram quae cillum dolore dolor amet nulla culpa multos export minim fugiat minim velit minim dolor enim duis veniam ipsum anim magna sunt elit fore quem dolore labore illum veniam.
              <i class="bx bxs-quote-alt-right quote-icon-right"></i>
            </p>
          </div>

          <div class="testimonial-item">
            <img src="../assets/butterfly/assets/img/testimonials/testimonials-5.jpg" class="testimonial-img" alt="">
            <h3>John Larson</h3>
            <h4>Entrepreneur</h4>
            <p>
              <i class="bx bxs-quote-alt-left quote-icon-left"></i>
              Quis quorum aliqua sint quem legam fore sunt eram irure aliqua veniam tempor noster veniam enim culpa labore duis sunt culpa nulla illum cillum fugiat legam esse veniam culpa fore nisi cillum quid.
              <i class="bx bxs-quote-alt-right quote-icon-right"></i>
            </p>
          </div>

        </div>

      </div>
    </section> -->
    <!-- End Testimonials Section -->

    <!-- ======= Team Section ======= -->
    <!-- <section id="team" class="team section-bg">
      <div class="container">

        <div class="section-title">
          <h2>Team</h2>
          <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
        </div>

        <div class="row">

          <div class="col-lg-3 col-md-6 d-flex align-items-stretch">
            <div class="member">
              <div class="member-img">
                <img src="../assets/butterfly/assets/img/team/team-1.jpg" class="img-fluid" alt="">
                <div class="social">
                  <a href=""><i class="icofont-twitter"></i></a>
                  <a href=""><i class="icofont-facebook"></i></a>
                  <a href=""><i class="icofont-instagram"></i></a>
                  <a href=""><i class="icofont-linkedin"></i></a>
                </div>
              </div>
              <div class="member-info">
                <h4>Walter White</h4>
                <span>Chief Executive Officer</span>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 d-flex align-items-stretch">
            <div class="member">
              <div class="member-img">
                <img src="../assets/butterfly/assets/img/team/team-2.jpg" class="img-fluid" alt="">
                <div class="social">
                  <a href=""><i class="icofont-twitter"></i></a>
                  <a href=""><i class="icofont-facebook"></i></a>
                  <a href=""><i class="icofont-instagram"></i></a>
                  <a href=""><i class="icofont-linkedin"></i></a>
                </div>
              </div>
              <div class="member-info">
                <h4>Sarah Jhonson</h4>
                <span>Product Manager</span>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 d-flex align-items-stretch">
            <div class="member">
              <div class="member-img">
                <img src="../assets/butterfly/assets/img/team/team-3.jpg" class="img-fluid" alt="">
                <div class="social">
                  <a href=""><i class="icofont-twitter"></i></a>
                  <a href=""><i class="icofont-facebook"></i></a>
                  <a href=""><i class="icofont-instagram"></i></a>
                  <a href=""><i class="icofont-linkedin"></i></a>
                </div>
              </div>
              <div class="member-info">
                <h4>William Anderson</h4>
                <span>CTO</span>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 d-flex align-items-stretch">
            <div class="member">
              <div class="member-img">
                <img src="../assets/butterfly/assets/img/team/team-4.jpg" class="img-fluid" alt="">
                <div class="social">
                  <a href=""><i class="icofont-twitter"></i></a>
                  <a href=""><i class="icofont-facebook"></i></a>
                  <a href=""><i class="icofont-instagram"></i></a>
                  <a href=""><i class="icofont-linkedin"></i></a>
                </div>
              </div>
              <div class="member-info">
                <h4>Amanda Jepson</h4>
                <span>Accountant</span>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section> -->

    <!-- End Team Section -->

    <!-- ======= Gallery Section ======= -->
    <!-- <section id="gallery" class="gallery">
      <div class="container">

        <div class="section-title">
          <h2>Gallery</h2>
          <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
        </div>

        <div class="row no-gutters">

          <div class="col-lg-3 col-md-4">
            <div class="gallery-item">
              <a href="../assets/butterfly/assets/img/gallery/gallery-1.jpg" class="venobox" data-gall="gallery-item">
                <img src="../assets/butterfly/assets/img/gallery/gallery-1.jpg" alt="" class="img-fluid">
              </a>
            </div>
          </div>

          <div class="col-lg-3 col-md-4">
            <div class="gallery-item">
              <a href="../assets/butterfly/assets/img/gallery/gallery-2.jpg" class="venobox" data-gall="gallery-item">
                <img src="../assets/butterfly/assets/img/gallery/gallery-2.jpg" alt="" class="img-fluid">
              </a>
            </div>
          </div>

          <div class="col-lg-3 col-md-4">
            <div class="gallery-item">
              <a href="../assets/butterfly/assets/img/gallery/gallery-3.jpg" class="venobox" data-gall="gallery-item">
                <img src="../assets/butterfly/assets/img/gallery/gallery-3.jpg" alt="" class="img-fluid">
              </a>
            </div>
          </div>

          <div class="col-lg-3 col-md-4">
            <div class="gallery-item">
              <a href="../assets/butterfly/assets/img/gallery/gallery-4.jpg" class="venobox" data-gall="gallery-item">
                <img src="../assets/butterfly/assets/img/gallery/gallery-4.jpg" alt="" class="img-fluid">
              </a>
            </div>
          </div>

          <div class="col-lg-3 col-md-4">
            <div class="gallery-item">
              <a href="../assets/butterfly/assets/img/gallery/gallery-5.jpg" class="venobox" data-gall="gallery-item">
                <img src="../assets/butterfly/assets/img/gallery/gallery-5.jpg" alt="" class="img-fluid">
              </a>
            </div>
          </div>

          <div class="col-lg-3 col-md-4">
            <div class="gallery-item">
              <a href="../assets/butterfly/assets/img/gallery/gallery-6.jpg" class="venobox" data-gall="gallery-item">
                <img src="../assets/butterfly/assets/img/gallery/gallery-6.jpg" alt="" class="img-fluid">
              </a>
            </div>
          </div>

          <div class="col-lg-3 col-md-4">
            <div class="gallery-item">
              <a href="../assets/butterfly/assets/img/gallery/gallery-7.jpg" class="venobox" data-gall="gallery-item">
                <img src="../assets/butterfly/assets/img/gallery/gallery-7.jpg" alt="" class="img-fluid">
              </a>
            </div>
          </div>

          <div class="col-lg-3 col-md-4">
            <div class="gallery-item">
              <a href="../assets/butterfly/assets/img/gallery/gallery-8.jpg" class="venobox" data-gall="gallery-item">
                <img src="../assets/butterfly/assets/img/gallery/gallery-8.jpg" alt="" class="img-fluid">
              </a>
            </div>
          </div>

        </div>

      </div>
    </section> -->
    <!-- End Gallery Section -->

    <!-- ======= Contact Section ======= -->
    <section id="contact" class="contact ">
      <div class="container">

        <div class="section-title">
          <h2>Contattaci ora</h2>
          <p class="p-style">Se sei interessato, hai un qualsiasi dubbio o solo per curiosità, trovi qui sotto i nostri contatti<br>Clicca sopre i numeri di telefono o la mail per contattarci</p>
        </div>

        <!-- <div>
          <iframe style="border:0; width: 100%; height: 270px;" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621" frameborder="0" allowfullscreen></iframe>
        </div> -->

        <div class="row mt-5">

          <div class="col-lg-4">
            <div class="info">
            <a href="https://wa.me/393404526854"target="_blank">

            </a>

              <a href="mailto:business@prenota.cc" target="_blank"> <div class="email">
                <i class="icofont-envelope"></i>
                <h4>Email:</h4>
                <p>business@prenota.cc</p>
              </div></a>



            </div>

          </div>

          <div class="col-lg-8 mt-5 mt-lg-0">
            <form action="https://mailthis.to/franz.stupar@gmail.com" method="POST" encType="multipart/form-data" class="php-email-form">
              <div class="form-row">
                <div class="col-md-6 form-group">
                  <input [(ngModel)]='name'type="text" name="name" class="form-control" placeholder="Il tuo nome"/>
                  <div class="validate"></div>
                </div>
                <div class="col-md-6 form-group">
                  <input [(ngModel)]='email' type="email" name="_replyto" class="form-control" placeholder="La tua email"/>
                  <div class="validate"></div>
                </div>
              </div>
              <div class="form-group">
                <input [(ngModel)]='subject' type="text" class="form-control" name="subject" id="subject" placeholder="Titolo" data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" />
                <div class="validate"></div>
              </div>
              <div class="form-group">
                <textarea  [(ngModel)]='message' class="form-control" name="message" rows="5" placeholder="Scrivi il tuo messaggio"></textarea>
                <div class="validate"></div>
              </div>
              <div class="text-center"><button type="submit" (click)='send()'>Spedisci messaggio</button></div>
            </form>

          </div>

        </div>

      </div>
    </section><!-- End Contact Section -->
  <app-footer></app-footer>
  </main><!-- End #main -->

  <!-- ======= Footer ======= -->
  <!-- <footer id="footer">

    <div class="footer-newsletter">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6">
            <h4>News Letter</h4>
            <p>Iscriviti alla nostra news letter per ricevere tutti gli ultimi aggiornamenti</p>
            <form action="" method="post">
              <input type="email" name="email"><input type="submit" value="Iscriviti">
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="footer-top">
      <div class="container">
        <div class="row">

          <div class="col-lg-3 col-md-6 footer-contact">
            <h3>Butterfly</h3>
            <p>
              A108 Adam Street <br>
              New York, NY 535022<br>
              United States <br><br>
              <strong>Phone:</strong> +1 5589 55488 55<br>
              <strong>Email:</strong> info@example.com<br>
            </p>
          </div>

          <div class="col-lg-3 col-md-6 footer-links">
            <h4>Useful Links</h4>
            <ul>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Home</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#">About us</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Services</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Terms of service</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Privacy policy</a></li>
            </ul>
          </div>

          <div class="col-lg-3 col-md-6 footer-links">
            <h4>Our Services</h4>
            <ul>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Web Design</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Web Development</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Product Management</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Marketing</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Graphic Design</a></li>
            </ul>
          </div>

          <div class="col-lg-3 col-md-6 footer-links">
            <h4>Our Social Networks</h4>
            <p>Cras fermentum odio eu feugiat lide par naso tierra videa magna derita valies</p>
            <div class="social-links mt-3">
              <a href="#" class="twitter"><i class="bx bxl-twitter"></i></a>
              <a href="#" class="facebook"><i class="bx bxl-facebook"></i></a>
              <a href="#" class="instagram"><i class="bx bxl-instagram"></i></a>
              <a href="#" class="google-plus"><i class="bx bxl-skype"></i></a>
              <a href="#" class="linkedin"><i class="bx bxl-linkedin"></i></a>
            </div>
          </div>

        </div>
      </div>
    </div> -->




  <!-- Vendor JS Files -->
  <style>
  /**
* Template Name: Butterfly - v2.0.0
* Template URL: https://bootstrapmade.com/butterfly-free-bootstrap-theme/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  font-family: "Open Sans", sans-serif !important;
  color: #444444;
}

a {
  color: #49b5e7;
}

a:hover {
  color: #76c7ed;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Open Sans", sans-serif !important;
}

/*


/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  height: var(--full-80-heigth);
  background: #fff;
}

#hero .container {
  padding-top: 84px;
}

#hero h1 {
  margin: 0;
  font-size: 42px;
  font-weight: 700;
  line-height: 56px;
  color: #0f394c;
  text-transform: uppercase;
}

#hero h2 {
  color: #555;
  margin: 10px 0 0 0;
  font-size: 20px;
}

.btn-get-started {
  letter-spacing: 1px;
  display: inline-block;
  padding: 15px 20px;
  border-radius: 3px;
  transition: 0.5s;
  margin-top: 25px;
  color: #fff !important;
  cursor: pointer;
  transition: 0.3s;
  font-size: 15px;
  line-height: 20px;
  font-family: "Open Sans", sans-serif !important;
  text-transform: uppercase;
  font-weight: 500;
  background: #0061d5;
  border: none;
}

.btn-get-started:hover {
  background: #0084ff;
}
.more-info{
  font-family: "Open Sans", sans-serif !important;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  cursor: pointer;
  letter-spacing: 1px;
  display: inline-block;
  padding: 14px 20px;
  border-radius: 3px;
  transition: 0.5s;
  margin-top: 25px;
  margin-right: 20px;
  border: solid 1px  #0f394c;
  color: #0f394c;
  background: #fff;
  text-transform: uppercase;
}
@media (max-width: 991px) {
  #hero {
    height: var(--full-heigth);
    text-align: center;
  }
  #hero .hero-img img {
    width: 50%;
  }
}

@media (max-width: 768px) {
  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }
  #hero h2 {
    font-size: 18px;
    line-height: 24px;
  }
  #hero .hero-img img {
    width: 80%;
  }
}
.head-45{
  padding: 45px 0 !important;
}
@media (max-width: 575px) {
.more-info{
  padding: 10px 15px!important;
  margin: 10px 0px 0px 0px!important;
}
.btn-get-started {
  padding: 10px 15px!important;
  margin: 10px 20px 0px 20px!important;
}
}
/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
}

.section-bg {
  background-color: #f4fbfe;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 36px;
  padding: 10px 0;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 0;
  color: #0f394c;
}

.section-title p {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about .icon-boxes h3 {
  font-size: 28px;
  font-weight: 700;
  color: #0f394c;
  margin-bottom: 15px;
}

.about .icon-box {
  margin-top: 40px;
}

.about .icon-box .icon {
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  border: 2px solid #d0ecf9;
  border-radius: 50px;
  transition: 0.5s;
}

.about .icon-box .icon i {
  color: #49b5e7;
  font-size: 32px;
}

.about .icon-box:hover .icon {
  background: #49b5e7;
  border-color: #49b5e7;
}

.about .icon-box:hover .icon i {
  color: #fff;
}

.about .icon-box .title {
  margin-left: 85px;
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 18px;
}

.about .icon-box .title a {
  color: #343a40;
  transition: 0.3s;
}

.about .icon-box .title a:hover {
  color: #49b5e7;
}

.about .icon-box .description {
  margin-left: 85px;
  line-height: 24px;
  font-size: 14px;
}
/*
.about .video-box {

} */


/*--------------------------------------------------------------
# Counts
--------------------------------------------------------------*/
.counts {
  /* background: url("../assets/butterfly/assets/img/counts-bg.jpg") center center no-repeat; */
  background-size: cover;
  padding: 80px 0 0px 0;
  /* background: #ebf7fc; */
  position: relative;
}

.counts::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.counts .title {
  position: relative;
  color: #0f394c;
  margin-bottom: 40px;
}

.counts .title h3 {
  font-size: 36px;
  font-weight: 700;
}

.counts .counters span {
  font-size: 44px;
  font-weight: 700;
  display: block;
  color: #49b5e7;
  font-family: "Open Sans", sans-serif !important;
}

.counts .counters p {
  padding: 0;
  margin: 0 0 20px 0;
  font-size: 15px;
  color: #444444;
}

@media (min-width: 1200px) {
  .counts {
    background-attachment: fixed;
  }
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .icon-box {
  padding: 30px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  margin: 0 10px 40px 10px;
  background: #fff;
  box-shadow: 0 0 15px 1px #ddd !important;
  transition: all 0.3s ease-in-out;
}

.services .icon-box:hover {
  transform: translateY(-5px);
}

.services .icon {
  position: absolute;
  left: 0px;
  top: calc(50% - 30px);
}

.services .icon i {
  opacity: 0.8;
  font-size: 50px;
  line-height: 1;
  transition: 0.5s;
}

.services .title {
  margin-left: 40px;
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
}

.services .title a {
  color: #111;
}

.services .icon-box:hover .title a {
  color: #49b5e7;
}

.services .description {
  font-size: 14px;
  margin-left: 40px;
  line-height: 24px;
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info {
  width: 100%;

}

.contact .info i {
  font-size: 20px;
  color: #49b5e7;
  float: left;
  width: 44px;
  height: 44px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}

.contact .info h4 {
  padding: 0 0 0 60px;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #0f394c;
}

.contact .info p {
  padding: 0 0 0 60px;
  margin-bottom: 0;
  font-size: 14px;
  color: #2079a1;
}

.contact .info .email, .contact .info .phone {
  margin-top: 40px;
  background:#fff
}

.contact .info .email:hover i, .contact .info .address:hover i, .contact .info .phone:hover i {
  background: #0061d5;
  color: #fff;
}

.contact .php-email-form {
  width: 100%;
  /* background-color: #f4fbfe; */
}

.contact .php-email-form .form-group {
  padding-bottom: 8px;
}

.contact .php-email-form .validate {
  display: none;
  color: red;
  margin: 0 0 15px 0;
  font-weight: 400;
  font-size: 13px;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input, .contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  background: #f4fbfe !important;
  font-size: 14px;
  border-radius: 4px;
}

.contact .php-email-form input {
  height: 44px;
}

.contact .php-email-form textarea {
  padding: 10px 12px;
}

.contact .php-email-form button[type="submit"] {
  letter-spacing: 1px;
  display: inline-block;
  padding: 15px 28px;
  border-radius: 3px;
  transition: 0.5s;
  margin-top: 25px;
  color: #fff;
  cursor: pointer;
  transition: 0.3s;
  font-size: 15px;
  line-height: 20px;
  font-family: "Open Sans", sans-serif !important;
  text-transform: uppercase;
  font-weight: 500;
  background: #0061d5;
  border: none;
}

.contact .php-email-form button[type="submit"]:hover {
  background: #1da2e0;
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>

  <!-- Load Facebook SDK for JavaScript -->


