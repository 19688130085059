<body>
        <!--============================= HEADER =============================-->

        <app-header></app-header>
        <section class="main-block categories">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-12">
                        <div class="styled-heading">
                            <h3 class="cat-heading"> Seleziona una categoria di servizi a Trieste</h3>
                        </div>
                    </div>
                </div>
                <div class="cards-list" >
                  <a [routerLink]="['/home/ricerca/parrucchieri']">
                    <div class="card 1" >
                      <div class="card_image"> <img src="../../assets/card-images/undraw_barber_3uel (1).svg" /> </div>
                      <div class="card_title">
                        <p>Parrucchieri</p>
                      </div>
                    </div>
                  </a>
                  <a [routerLink]="['/home/ricerca/medici']">
                      <div class="card 2" >
                      <div class="card_image">
                        <img src="../../assets/card-images/undraw_doctors_hwty (1).svg" />
                        </div>
                      <div class="card_title ">
                        <p>Salute</p>
                      </div>
                    </div>
                  </a>
                  <a [routerLink]="['/home/ricerca/campi']">
                    <div class="card 3" >
                        <div class="card_image">
                          <img src="../../assets/undraw_grand_slam_0q5r.svg" />
                        </div>
                        <div class="card_title">
                          <p>Campi</p>
                        </div>
                      </div>
                    </a>
                  <a [routerLink]="['/home/ricerca/curadite']">
                    <div class="card 4" >
                      <div class="card_image">
                        <img src="../../assets/card-images/undraw_mindfulness_scgo (1).svg" />
                      </div>
                      <div class="card_title">
                        <p>Cura di te</p>
                      </div>
                    </div>
                  </a>
                  </div>
                   
            </div>
        </section>    
        <section class="main-block light-bg">
            <div class="container">
                <div class="row">
                    <div class="offset-md-2 col-md-8" style="text-align: center;">
                        
                            <h2 class="cat-heading">Permetti anche ai tuoi clienti di prenotare online e da app</h2>
                            <p style="color: #666; padding-bottom: 40px;">Non perdere tempo facendo quello che può fare un computer, lascia che ce ne occupiamo noi per te</p>
                        
                    </div>
                </div>
                <div class="row justify-content-center">
                        <div class="featured-btn-wrap">
                            <a [routerLink]="['/business']"><span  class="btn btn--blue" >Scopri di più</span> </a>             
                    </div>
                </div>
            </div>
        </section>
        <app-footer></app-footer>
    </body>