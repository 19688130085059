
    <link href="https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i" rel="stylesheet">

    

<body>
    
    <div class="page-wrapper bg-gra-02 p-t-70 p-b-30 font-poppins">
            <h1 class='logo-title' (click)='goHome()'>prenota</h1>
        <div class="wrapper wrapper--w680">
            <div class="card card-4">
                <div class="card-body">
                    <h2 class="title">Fai il login alla tua pagina <b>Business</b></h2>
                    <form >
                        <div class="row row-space">
                            <p *ngIf='error!=""'>{{error}}</p>
                                <div class="col-2-r">
                                        <div class="input-group">
                                            <label class="label">Email</label>
                                            <input class="input--style-4" [(ngModel)]="email" type="email" name="email" placeholder="mario.rossi@email.com">
                                        </div>
                                    </div>
                                    <div class="col-2-r">
                                        <div class="input-group">
                                            <label class="label">Password</label>
                                            <input class="input--style-4" [(ngModel)]="password" type="password" name="password"  placeholder="********">
                                        </div>
                                    </div>
                                </div>
                        <div class="p-t-15">
                            <button class="btn btn--radius-2 btn--blue"  (click)='login()' >Login</button>
                        </div>
                    </form>
                </div>
                <div class="bottom-login">Hai dimenticato la password? <a (click)='goResetPassword()'>Cambiala</a></div>
                <div class="bottom-login">Vuoi fare una prenotazione? <a (click)='goRegister()'>Clicca qui</a></div>
            </div>
        </div>
    </div>

    

</body>