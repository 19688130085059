
      <body id="body" data-spy="scroll" data-target=".navbar" data-offset="100">
        <app-header></app-header>
        <div class="banner">
          <div class="container">
            <h1 class="font-weight-semibold"><br>Prenotazione avvenuta con successo!</h1>
            <img src="../assets/undraw_order_confirmed_aaw7.svg" alt="" style='height:40vh' class="img-fluid" /> 
            <h6 class="font-weight-normal text-muted pb-3">
              <br>
            Grazie di aver prenotato.<br><br>Riceverai una mail di conferma con tutti i dati della prenotazione. 

            </h6>
            <div>
              <button class="btn btn-opacity-light mr-1" (click)="navListing()">Torna alla home</button>
              <button class="btn btn-opacity-success-1 ml-1" (click)="navBusiness()">Le tue prenotazioni</button>
            </div>
           
          </div>
        </div>
            <app-footer></app-footer>
            </body>